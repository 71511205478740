// import { i18n } from '@/plugins/i18n'
import { QueryTypes } from '@/store/core/actions'

export default {
  namespaced: true,
  state: {
    shallContentShowOverlay: false,
    notification: {
      text: '',
      visible: false,
      timeout: -1,
      color: '',
    },
    newVersionNotification: {
      visible: false,
    },
  },
  getters: {},
  mutations: {
    TOGGLE_CONTENT_OVERLAY(state, value) {
      state.shallContentShowOverlay = value !== undefined ? value : !state.shallContentShowOverlay
    },
    SET_SUCCESS_SNACK(state, text) {
      state.notification = {
        text,
        visible: true,
        timeout: 1500,
        color: 'success',
      }
    },
    SET_ERROR_SNACK(state, text) {
      state.notification = {
        text,
        visible: true,
        timeout: 3000,
        color: 'error',
      }
    },

    SET_INFO_SNACK(state, text) {
      state.notification = {
        text,
        visible: true,
        timeout: 3000,
        color: 'info',
      }
    },

    SET_NEW_VERSION_NOTIFICATION(state) {
      state.newVersionNotification = {
        visible: true,
      }
    },

    REMOVE_NEW_VERSION_NOTIFICATION(state) {
      state.newVersionNotification = {
        visible: false,
      }
    },
  },
  actions: {
    async checkVersion({ commit, dispatch }) {
      try {
        const { success, data } = await dispatch(
          'core/axiosQueryBuilder',
          {
            type: QueryTypes.GET,
            url: `version/`,
          },
          {
            root: true,
          },
        )
        if (!success) return
        const backendVersion = data.version?.split('.').map((el) => parseInt(el, 10))
        const frontedVersion = process.env.VUE_APP_VERSION.split('.').map((el) => parseInt(el, 10))
        if (backendVersion[0] === frontedVersion[0]) {
          if (backendVersion[1] === frontedVersion[1]) {
            if (backendVersion[2] > frontedVersion[2]) {
              commit('SET_NEW_VERSION_NOTIFICATION')
            }
          } else if (backendVersion[1] > frontedVersion[1]) {
            commit('SET_NEW_VERSION_NOTIFICATION')
          }
        } else if (backendVersion[0] > frontedVersion[0]) {
          commit('SET_NEW_VERSION_NOTIFICATION')
        }
      } catch (e) {
        console.error(e)
      }
    },
  },
}
