import axios from 'axios'
import { QueryTypes } from '@/store/core/actions'

export const getCompanyName = async (_) => {
  try {
    const { data } = await axios.get('api/v1/company/current/name/')

    return data
  } catch (e) {
    console.error(e)
  }
}

export const getCompanySettings = async ({ commit, dispatch }) => {
  const { success, data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/current/`,
    },
    { root: true },
  )
  commit('SET_COMPANY_SETTINGS', data)

  return { success, data }
}

export const updateCompanySettings = async ({ commit, dispatch }, companySettings) => {
  const { id, ...payload } = companySettings
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/${id}/`,
      payload,
    },
    { root: true },
  )
  commit('SET_COMPANY_SETTINGS', data)

  return { data, success }
}

export const getAdditionalConsents = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/gdpr/additional-consent/`,
    },
    { root: true },
  )

  return data
}

export const manageAdditionalConsents = async ({ dispatch }, { companyId, payload }) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/gdpr/${companyId}/additional-consent/manage/`,
      payload: {
        additionalConsents: payload,
      },
    },
    { root: true },
  )

  return { data, success }
}

export const getCompanyGDPRSettings = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/gdpr/`,
    },
    { root: true },
  )

  return data
}

export const updateCompanyGDPRSettings = async ({ dispatch }, { id, payload }) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/gdpr/${id}/`,
      payload,
    },
    { root: true },
  )

  return { data, success }
}

export const getAllRecruiters = async ({ commit, dispatch }, { search }) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `recruiter/profile/all/`,
      params: {
        search,
      },
    },
    { root: true },
  )
  if (success) commit('SET_RECRUITERS', data)

  return success
}

export const getAllRecruitersOverview = async ({ dispatch }) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `recruiter/profile/all/overview`,
    },
    { root: true },
  )

  return { data, success }
}

export const getRecruitersChoices = async ({ commit, dispatch }) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `recruiter/profile/choices/`,
    },
    { root: true },
  )
  if (success) commit('SET_RECRUITERS', data)

  return data
}

export const getRecruiterData = async ({ dispatch }, { id }) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `recruiter/profile/${id}/`,
    },
    { root: true },
  )

  return { data, success }
}

export const updateRecruiter = async ({ dispatch }, { id, payload }) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `recruiter/profile/${id}/`,
      payload: { access: payload.access, user: payload.user },
    },
    { root: true },
  )

  return { data, success }
}

export const deleteRecruiter = async ({ dispatch }, { id }) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.DELETE,
      url: `recruiter/profile/${id}/`,
    },
    { root: true },
  )

  return { data, success }
}

export const changeRecruiterPassword = async ({ dispatch }, { id, newPassword }) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `recruiter/profile/${id}/change-password/`,
      payload: { newPassword },
    },
    { root: true },
  )

  return { data, success }
}

export const getSettings = async ({ commit, dispatch }) => {
  // TODO: maybe disable error info?
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/business-settings/`,
    },
    { root: true },
  )

  commit('SET_BUSINESS_SETTINGS', data)

  return data
}

export const getCandidateCustomFields = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/candidate/custom-fields/`,
    },
    { root: true },
  )

  return data
}

export const updateCandidateCustomFields = async ({ dispatch }, payload) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/candidate/custom-fields/`,
      payload,
    },
    { root: true },
  )

  return data
}

export const getCandidateTags = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/candidates/tags/`,
    },
    { root: true },
  )

  return data
}

export const updateCandidateTags = async ({ dispatch }, payload) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/candidates/tags/`,
      payload,
    },
    { root: true },
  )

  return data
}

export default {
  // -------!!APi
  getCompanyName,
  getCompanySettings,
  updateCompanySettings,
  getAdditionalConsents,
  manageAdditionalConsents,
  getCompanyGDPRSettings,
  updateCompanyGDPRSettings,
  getAllRecruiters,
  getAllRecruitersOverview,
  getRecruitersChoices,
  getRecruiterData,
  updateRecruiter,
  deleteRecruiter,
  getSettings,
  getCandidateCustomFields,
  updateCandidateCustomFields,
  getCandidateTags,
  updateCandidateTags,
}
