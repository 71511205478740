import { mutationFactory } from 'vuex-factories'
import actions from './actions'

export default {
  namespaced: true,
  state: {
    recruitment: {
      waitingCandidates: [],
      inProgressCount: 0,
      hiredCount: 0,
      rejectedCount: 0,
      countedSources: {
        labels: [],
        data: [],
      },
      countedRejectReasons: {
        labels: [],
        data: [],
      },
      candidatesIn30Days: {
        labels: [],
        data: [],
      },
      statisticCandidatesIn30Days: 0,
    },
    talentPool: {
      allValidEntries: 0,
      validCount: 0,
      expiredCount: 0,
      anonymizationRequestCount: 0,
      autoAnonymizationDisabledCount: 0,
    },
    employee: {
      countedStatuses: {
        labels: [],
        data: [],
      },
      employeesIn30Days: {
        labels: [],
        data: [],
      },
      incomingReminders: [],
    },
    house: {
      countedStatuses: { labels: [], data: [] },
      housesCount: 0,
      roomsCount: 0,
      occupantCount: 0,
      takenSeatsByEmployee: 0,
      takenSeatsByWho: 0,
      free: 0,
    },
    project: {
      countedProjectsStatuses: { labels: [], data: [] },
      projectsAtRisk: [],
      positionAmount: 0,
    },
    client: {
      countedClientsStatuses: {
        labels: [],
        data: [],
      },
      projectsAmount: 0,
      clientsAmount: 0,
      demandSum: 0,
      assigneesCount: 0,
      workload: 0,
    },
  },
  actions,
  mutations: {
    ...mutationFactory({
      SET_DASHBOARD_RECRUITMENT_DATA: [{ key: 'recruitment' }],
      SET_DASHBOARD_TALENT_POOL_DATA: [{ key: 'talentPool' }],
      SET_DASHBOARD_EMPLOYEE_DATA: [{ key: 'employee' }],
      SET_DASHBOARD_HOUSE_DATA: [{ key: 'house' }],
      SET_DASHBOARD_PROJECT_DATA: [{ key: 'project' }],
      SET_DASHBOARD_CLIENT_DATA: [{ key: 'client' }],
    }),
  },
}
