import { mdiBellOutline } from '@mdi/js'
import { PRIVATE } from '@/plugins/acl/const'

export default [
  {
    title: 'reminders',
    to: 'reminders',
    icon: mdiBellOutline,
    resource: PRIVATE.REMINDERS,
    action: 'read',
  },
]
