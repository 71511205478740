export default {
  acceptRequest: 'Zaakceptuj żądanie',
  acceptedConsent: 'Zaakceptowano ',
  access: 'Dostęp',
  account: 'Konto',
  actions: 'Akcje',
  add: 'Dodaj',
  addCandidate: 'Dodaj kandydata',
  addCategory: 'Dodaj kategorię',
  addClient: 'Dodaj klienta',
  addCustomFiled: 'Dodaj pole własne',
  addFile: 'Dodaj plik',
  addHouse: 'Dodaj mieszkanie',
  addInternalNote: 'Dodaj notatkę wewnętrzną',
  addOccupant: 'Dodaj mieszkańca',
  addPosition: 'Dodaj stanowisko',
  addProject: 'Dodaj projekt',
  addQuestion: 'Dodaj pytanie',
  addRejectReason: 'Dodaj powód odrzucenia',
  addRejectReasonCategory: 'Dodaj kategorię powodów odrzuceń',
  addReminder: 'Dodaj przypomnienie',
  addRoom: 'Dodaj pokój',
  addSource: 'Dodaj źródło',
  addStep: 'Dodaj krok',
  addTag: 'Dodaj nowy tag',
  addVariable: 'Dodaj zmienną',
  additionalConsent: 'Dodatkowa zgoda',
  additionalConsents: 'Dodatkowe zgody',
  address: 'Adres',
  allCapacity: 'Całe zapotrzebowanie',
  allSeats: 'Wszystkie miejsca',
  anonymizationRequest: 'Żądanie anonimizacji',
  requestAccepted: 'Żądanie anonimizacji zaakceptowane',
  requestDeclined: 'Odrzucono żądanie anonimizacji',
  anonymizationRequestRemaining: 'Żądanie zanonimizowania pozostało',
  anonymized: 'Zaanonimizowano',
  anotherAddress: 'Inny adres',
  apartments: 'Mieszkania',
  applyEmailTemplate: 'Wybierz wzór emaila wysłanego po zaaplikowaniu',
  applyForm: 'Formularz aplikacyjny',
  applyFormError: 'Uzupełnij klauzule RODO lub / i nazwę rejestrową firmy',
  applyFormLink: 'Link do formularza aplikacyjnego',
  archived: 'Zarchiwizowane',
  assigned: 'Przypisany',
  assignedTo: 'Przypisany do',
  assignees: 'Pracownicy',
  attachedToFile: 'Dołączony do pliku',
  attachments: 'Załączniki',
  attachmentsCount: 'Załączniki - {sum} plik(i)',
  attachmentsRequired: 'Wymagane załączniki',
  autoAnonymizationDisabled: 'Automatyczna anonimzacja wyłączona',
  back: 'Poprzedni',
  cancel: 'Anuluj',
  candidateFirstName: 'Imię kandydata',
  candidateLastName: 'Nazwisko kandydata',
  candidateStepFrom: 'Poprzedni krok',
  candidateStepTo: 'Następny krok',
  candidates: 'Kandydaci',
  candidatesCounterCard: '{count} kandydatów',
  candidatesOnStageChart: 'Kandydaci na etapach',
  candidatesOnStepChart: 'Kandydaci na krokach',
  candidatesSourcesChart: 'Źródła kandydatów',
  cc: 'DW',
  charts: 'Wykresy',
  city: 'Miasto',
  clear: 'Wyczyść',
  clearButton: 'Wyczyść filtry',
  clientInformation: 'Informacje o kliencie',
  clientName: 'Nazwa klienta',
  clientRegistrationName: 'Nazwa rejestrowa firmy',
  clientStatuses: 'Statusy klienta',
  clients: 'Klienci',
  close: 'Zamknij',
  company: 'Firma',
  companyDetails: 'Informacje o firmie',
  companyName: 'Nazwa firmy',
  companyRegistrationName: 'Nazwa rejestrowa',
  confirm: 'Potwierdź',
  confirmPassword: 'Potwierdź hasło',
  confirmationModalDefaultEmail: 'Zostanie użyty domyślny szablon emaila.',
  confirmationModalDefaultEmailConditions:
    'Jeżeli temat lub treść pozostanie pusta, zostanie użyty domyślny szablon emaila.',
  confirmationModalUndoneQuestion: 'Operacja nie może zostać cofnięta. Czy chcesz kontrynuować?',
  confirmationModalUnsavedChanges: 'Masz niezapisane zmiany. Czy na pewno chcesz wyjść bez zapisywania?',
  consent: 'Zgoda',
  contactConsent: 'Zgoda kontaktu',
  contactEmail: 'Email do kontaktu',
  continue: 'Następny',
  copiedToClipboard: 'Skopiowano do schowka',
  copyToClipboard: 'Skopiuj do schowka',
  copyTooltip: 'Kopiuj',
  country: 'Kraj',
  create: 'Stwórz',
  createAccount: 'Utwórz konto',
  createAccountConsentAction: 'Zaznacz tutaj, aby zaznaczyć, że zapoznałeś się i akceptujesz',
  addEmployee: 'Dodaj pracownika',
  createJob: 'Dodaj ofertę',
  createdBy: 'Dodany przez',
  current: 'Obecny',
  currentAddress: 'Obecny adres',
  currentPassword: 'Obecne hasło',
  currentStep: 'Obecny krok',
  customAddress: 'Własny adres',
  customFields: 'Własne pola',
  dashboard: 'Panel',
  declineRequest: 'Odrzuć żądanie',
  declinedConsent: 'Odrzucona',
  defaultApplied: 'Domyślny kiedy zaaplikował',
  defaultHired: 'Domyślny kiedy zatrudnił',
  defaultInviteFromTalentPool: 'Domyślny kiedy zaprosił do rekrutacji z bazy talentów',
  defaultNextStep: 'Domyślny kiedy przesunął do następnego kroku',
  defaultReject: 'Domyślny kiedy odrzucił',
  defaultRenewTalentPoolConsent: 'Domyślny kiedy odnowił zgodę bazy talentów',
  delete: 'Usuń',
  deleteJob: 'Usuń ofertę',
  deleteOn: 'Usunięty dnia',
  deleteWorkflow: 'Usuń proces',
  demand: 'Zapotrzebowanie',
  description: 'Opis',
  details: 'Szczegóły',
  done: 'Wykonano',
  dontShowWhoInvited: 'Nie pokazuj, kto zaprosił kandydata',
  draft: 'Szkice',
  duplicate: 'Duplikuj',
  editor: 'Edytor',
  email: 'Email{postfix}',
  emailFormat: 'Email ma błędny format',
  emailRequired: 'Wymagany email',
  emailSeparator: 'Emaile muszą być przedzielone przecinkiem bez spacji',
  employee: 'Pracownik',
  employeeStatuses: 'Statusy pracowników',
  employees: 'Pracownicy',
  eng: 'Angielski',
  expand: 'Rozwiń',
  expired: 'Wygasł',
  field: 'Pole',
  filePondIdle: "Przeciągnij pliki tutaj lub <span class='filepond--label-action'>kliknij</span> żeby je wybrać",
  fileReminders: 'Przypomnienia plików',
  files: 'Pliki',
  filesWithoutType: 'Pliki bez kategorii',
  filterBy: 'Filtruj po',
  finished: 'Zakończone',
  firstName: 'Imię{postfix}',
  firstNameRequired: 'Imię*',
  flatNumber: 'Numer mieszkania',
  gdpr: 'RODO',
  gdprStatus: 'Status RODO',
  generalReminders: 'Przypomnienia ogólne',
  header: 'RODO',
  hello: 'Witaj, {name}',
  hideFullHouses: 'Ukryj pełne domy',
  hireCandidate: 'Zatrudnij kandydata',
  hireOption: 'Zatrudnij kandydata',
  hired: 'Zatrudniony',
  hire: 'Zatrudnij',
  houseNumber: 'Numer domu',
  houseStatuses: 'Statusy mieszkań',
  houses: 'Mieszkania',
  inProgress: 'W toku',
  incoming: 'Nadchodzące',
  information: 'Informacje',
  internalNoteCreatedByWithStep: '{createdBy} podczas kroku {step}',
  internalNoteCreatedByWoStep: '{createdBy}',
  internalNotes: 'Notatki wewnętrzne',
  invalidBulkCombination: 'Nie można wybrać żadnej akcji w obecnej konfiguracji',
  invite: 'Zaproś',
  inviteJobRecruiterEmail: 'Zaproś do rekrutacji - email rekrutera',
  inviteJobRecruiterLastName: 'Zaproś do rekrutacji - nazwisko rekrutera',
  inviteJobRecruiterName: 'Zaproś do rekrutacji - imię rekrutera',
  inviteJobTitle: 'Zaproś do rekrutacji - tytuł',
  inviteJobUrl: 'Zaproś do rekrutacji - link',
  inviteToJob: 'Zaproś do rekrutacji',
  inviteUser: 'Zaproś uytkownika',
  invitedBy: 'Zaproszony przez',
  isInTalentPool: 'Jest w bazie talentów',
  jobDeleted: 'Oferta usunięta',
  jobDescription: 'Opis ofert',
  jobDetails: 'Szczegóły rekrutacji',
  jobHistory: 'Historia zatrudnienia',
  jobTitle: 'Tytuł rekrutacji',
  jobs: 'Rekrutacje',
  kids: 'Dzieci',
  language: 'Język',
  languages: 'Języki',
  lastName: 'Nazwisko{postfix}',
  lastNameRequired: 'Nazwisko*',
  latestAttachmentsCount: 'Ostatnie załączniki - {count} plik',
  latestJob: 'Ostatnia rekrutacja',
  localization: 'Lokalizacja',
  loggedIn: 'Zalogowano',
  loggedOut: 'Wylogowano',
  login: 'Zaloguj',
  logout: 'Wyloguj',
  manageQuestions: 'Zarządzaj pytaniami',
  manageSteps: 'Zarządzaj krokami',
  maxSalary: 'Maksymalne wynagrodzenie',
  minSalary: 'Minimalne wynagrodzenie',
  minimize: 'Zmniejsz',
  moveToArchive:
    'Czy jesteś pewien, że chcesz zmienić status tej rekrutacji na “archiwum”? Tej operacji nie da się cofnąć. Nie będziesz już mógł zobaczyć tej rekrutacji w panelu.',
  moveToFinished:
    'Czy jesteś pewien, że chcesz zmienić status tej rekrutacji na “zakończona”? Zmieniając status rekrutacji na zakończoną, odrzucimy wszystkich kandydatów, którzy są na statusie nowa lub w trakcie realizacji i poinformujemy ich o tym fakcie.',
  moveToNextStep: 'Przesuń do następnego kroku',
  moveToNextStepOption: 'Następny krok',
  moveToOngoing:
    'Czy jesteś pewien, że chcesz zmienić status tej rekrutacji na “ongoing”? Tej operacji nie da się cofnąć. Nie będziesz już mógł zobaczyć tej rekrutacji w panelu.',
  name: 'Nazwa',
  national: 'Narodowość',
  nationalAddress: 'Adres pochodzenia',
  nationality: 'Narodowość',
  new: 'Nowy',
  newPassword: 'Nowe hasło',
  newVersion: 'Nowa wersja systemu jest dostępna.',
  nextCandidate: 'Następny kandydat',
  noData: 'Brak danych',
  notAssigned: 'Nie przypisane',
  note: 'Notatka',
  places: 'Miejsca',
  occupied: 'Zajęte',
  ongoing: 'Trwające',
  organization: 'Organizacja',
  otherAvailableOccupants: 'Reszta dostępnych miejsc',
  password: 'Hasło',
  personalData: 'Dane osobowe',
  personalDataConsent: 'Zgoda na przetwarzanie danych osobowych',
  personalDataDeleteRetention: 'Przechowywanie usuniętych danych osobowych',
  personalDataDeleteRetentionHint:
    'Jak długo można przetwarzać dane od momentu zaakceptowania wniosku o anonimizację, w dniach',
  personalDataProcessingPeriod: 'Okres przetwarzania danych osobowych',
  personalDataProcessingPeriodHint: 'Jak długo można przetwarzać dane od momentu wyrażenia zgody, w miesiącach',
  phoneLength: 'Maksymalnie 31 znaków',
  phoneNumber: 'Numer telefonu',
  phoneNumberRequired: 'Wymagany numer telefonu',
  pl: 'Polski',
  position: 'Stanowisko',
  positions: 'Stanowiska',
  positionsAmount: 'Ilość stanowisk',
  positiveOnlyNumber: 'Wartość musi być większa bądź równa 0',
  preferredCity: 'Preferowane miasto',
  preferredCityRequired: 'Preferowane miasto wymagane',
  preferredCityVisible: 'Preferowane miasto widoczne',
  preview: 'Podgląd',
  previousCandidate: 'Poprzedni kandydat',
  privacyPolicy: 'Polityka prywatności',
  projectStatuses: 'Statusy projektów',
  projects: 'Projekty',
  projectsAmount: 'Ilość projektów',
  question: 'Pytanie',
  questions: 'Pytania',
  recruiterEmail: 'Email rekrutera',
  recruiterFirstName: 'Imię rekrutera',
  recruiterLastName: 'Nazwisko rekrutera',
  refresh: 'Odśwież',
  registrationName: 'Nazwa rejestrowa',
  reject: 'Odrzuć',
  rejectCandidate: 'Odrzuć kandydata',
  rejectOption: 'Odrzuć kandydata',
  rejectReason: 'Powód odrzucenia',
  rejectReasonCategory: 'Kategoria powodów odrzuceń',
  rejectReasons: 'Powody odrzucenia',
  rejected: 'Odrzucony',
  remindDate: 'Data przypomnienia',
  reminders: 'Przypomnienia',
  renewConsentUrl: 'Link do odnowienia zgody',
  required: 'Wymagany',
  resendConfirmationEmail: 'Wyślij ponownie e-mail',
  resetPassword: 'Zresetuj hasło',
  rooms: 'Pokoje',
  ru: 'Rosyjski',
  save: 'Zapisz',
  search: 'Szukaj',
  searchInput: 'Czego szukasz?',
  selectEmail: 'Wybierz email',
  selectJob: 'Wybierz ofertę',
  selectRecruiter: 'Wybierz rekrutera',
  selectRejectReason: 'Wybierz powód odrzucenia',
  selectSource: 'Wybierz źródło',
  selectWorkflow: 'Wybierz proces',
  sendMeCopy: 'Wyślij kopię do mnie',
  sendTestEmail: 'Wyślij email testowy',
  separateByComma: 'E-maile powinny być oddzielone przecinkami',
  setForAnonymization: 'Do anonimizacji',
  settings: 'Ustawienia',
  sharedWith: 'Udostępnione',
  showJobDescription: 'Pokaż opis rekrutacji',
  showTalentPoolConsent: 'Pokaż zgodę dla bazy kandydatów',
  somethingWentWrong: 'Coś poszło nie tak',
  source: 'Źródło',
  sourceCategory: 'Kategoria źródła',
  sources: 'Źródła',
  staff: 'Pracownicy',
  stage: 'Etap',
  state: 'Województwo',
  status: 'Status{postfix}',
  step: 'Krok',
  stepsCount: 'Kroki: {count}',
  street: 'Ulica',
  subject: 'Temat',
  tag: 'Tag',
  tags: 'Tagi',
  takenOccupants: 'Zajęte miejsca',
  takenSeats: 'Zajęte miejsca',
  talentPool: 'Baza talentów',
  talentPoolConsents: 'Zgody bazy kandydatów',
  talentPoolProcessingPeriod: 'Okres przetwarzania w puli talentów',
  talentPoolProcessingPeriodHint: 'Jak długo można przetwarzać dane od momentu wyrażenia zgody, w miesiącach',
  talentPoolRenewEmail: 'Wyślij e-mail, aby odnowić zgodę przed jej wygaśnięciem',
  talentPoolRenewEmailTemplate: 'Szablon e-maila odnawiającego',
  talentPoolRetention: 'Retencja zgody dla bazy talentów (w miesiącach)',
  taxId: 'NIP',
  templates: 'Szablony',
  termsAndConditions: 'Zasady i warunki',
  testEmail: 'Testowy email',
  title: 'Tytuł',
  type: 'Typ',
  undo: 'Cofnij',
  updatePassword: 'Zaktualizuj hasło',
  updateProfile: 'Zaktualizuj profil',
  url: 'Link',
  usedEmailVariables: 'Wykorzystane zmienne w emailu',
  userManagement: 'Zarządzanie użytkownikami',
  valid: 'Ważny',
  validUntil: 'Ważny do',
  workflow: 'Proces',
  workflowTemplateDeleted: 'Szablon procesu usunięty',
  workload: 'Poziom zatrudnienia',
  zip: 'Kod pocztowy',
  selectTag: 'Wybierz tag',
  selectStatus: 'Wybierz status',
  selectLanguage: 'Wybierz język',
  startWorkingDay: 'Dzień rozpoczęcia pracy',
  martialStatus: 'Stan cywilny',
  room: 'Pokój',
  removeReminder: 'Usuń przypomnienie',
  removeFile: 'Usuń plik',
  discard: 'Anuluj',
  user: 'Użytkownik',
  editEmployeeInformation: 'Edytuj dane pracownika',
  permanentResidence: 'Adres zameldowania',
  reminder: 'Przypomnienie',
  addNewFile: 'Dodaj nowy plik',
  typeYourNote: 'Wpisz swoją notatkę',
  send: 'Wyślij',
  removeFileReminder: 'Usuń przypomnienie',
  total: 'Wszyscy',
  searchAndFilter: 'Wyszukiwanie i filtrowanie',
  seatsCounter: 'Licznik miejsc',
  selectCity: 'Wybierz miasto',
  addNewClient: 'Dodaj nowego klienta',
  deleteClient: 'Usuń klienta',
  viewClient: 'Wyświetl klienta',
  positionAmount: 'Liczba stanowisk',
  editClientInformation: 'Edytuj dane klienta',
  projectsWorkload: 'Wypełnienie projektów',
  assignee: 'Pracownik',
  addNewHouse: 'Dodaj nowe mieszkanie',
  viewDetails: 'Wyświetl szczegóły',
  free: 'Wolny',
  selectClient: 'Wybierz klienta',
  addNewProject: 'Dodaj nowy projekt',
  view: 'Wyświetl',
  basicData: 'Dane podstawowe',
  zipCode: 'Kod pocztowy',
  takenAssignees: 'Przypisani pracownicy',
  board: 'Tablica',
  newCandidates: 'Nowi kandydaci',
  incomingCandidates: 'Liczba kandydatów',
  employeeStats: 'Statusy pracowników',
  newEmployees: 'Nowi pracownicy',
  totalEmployees: 'Wszyscy pracownicy',
  sumAllEmployees: 'Liczba wszystkich pracowników',
  employeesWithoutStatus: 'Pracownicy bez statusu',
  sumAllEmployeesWithoutStatus: 'Suma wszystkich pracowników bez statusu',
  houseStatusOverview: 'Statusy mieszkań',
  houseCountedStatuses: 'Liczba mieszkań po statusie',
  projectStatusOverview: 'Statusy projektów',
  projectCountedStatuses: 'Liczba projektów po statusie',
  totalPositions: 'Wszystkie stanowiska',
  clientCountedStatuses: 'Liczba klientów po statusie',
  clientStatusOverview: 'Statusy klientów',
  levelOfEmployment: 'Poziom zatrudnienia',
  totalClients: 'Wszyscy klienci',
  totalProjects: 'Wszystkie projekty',
  candidatesStats: 'Statusy kandydatów',
  topSources: 'Najpopularniejsze źródła',
  candidatesSources: 'Źródła kandydatów',
  candidatesFromSources: 'Kandydaci ze źródła',
  candidatesRejectReasons: 'Powody odrzucenia kandydatów',
  topReasons: 'Najpopularniejsze powody',
  newUnhandledCandidates: 'Nowi kandydaci do sprawdzenia',
  noActionForXDays: 'Nieaktywny od {daysInStep} dni',
  projectEmployment: 'Realizacja projektów',
  upcomingReminders: 'Nadchodzące powiadomienia',
  accommodationStats: 'Statystyki mieszkań',
  accommodationUsage: 'Wykorzystanie mieszkań',
  occupiedByEmployees: 'Zajęte przez pracowników',
  occupiedByOthers: 'Zajęte przez innych',
  available: 'Dostępne',
  projectWarnings: 'Ostrzeżenia projektowe',
  talentPoolStats: 'Statystyki bazy talentów',
  allNonAnonymizedEntries: 'Liczba aktywnych wpisów',
  anonymizationRequested: 'Prośba o anonimizację',
  basicSettings: 'Ustawienia podstawowe',
  applyFormSettings: 'Ustawienia formularza',
  applyFormLanguage: 'Język formularza',
  action: 'Akcja',
  candidatesInOngoingJobs: 'Kandydaci w aktywnych rekrutacjach',
  totalCandidates: 'Wszyscy kandydaci',
  selectAssignedTo: 'Wybierz przypisanych',
  addJob: 'Dodaj ofertę',
  defaultEmailTemplates: 'Domyślne szablony email',
  emailTemplates: 'Szablony email',
  addNewEmailTemplate: 'Dodaj nowy szablon email',
  polish: 'Polski',
  english: 'Angielski',
  emailTo: 'Email do',
  variables: 'Zmienne',
  internalNote: 'Notatka wewnętrzna',
  workflowTemplates: 'Szablony procesów',
  addNewWorkflowTemplate: 'Dodaj nowy szablon',
  deleteStep: 'Usuń krok',
  apartmentStatuses: 'Statusy mieszkań',
  addStatus: 'Dodaj status',
  active: 'Aktywny',
  pending: 'Oczekujący',
  recruiter: 'Rekruter',
  expert: 'Ekspert',
  admin: 'Administrator',
  suspended: 'Zawieszony',
  overview: 'Podsumowanie',
  security: 'Bezpieczeństwo',
  jobList: 'Lista rekrutacji',
  candidatesAmount: 'Liczba kandydatów',
  ensureThatTheseRequirementsAreMet: 'Upewnij się, że te wymogi zostały spełnione',
  minimumCharacterLongUppercaseAndSymbol: 'Minimum 8 znaków, wielka litera i znak specjalny',
  changePassword: 'Zmień hasło',
  jobsFinished: 'Zakończone rekrutacje',
  jobsOngoing: 'Trwające rekrutacje',
  role: 'Rola{postfix}',
  newCandidatesAmount: '{amount} nowych kandydatów',
  extraData: 'Dodatkowe dane',
  file: 'Plik',
  jobStage: 'Status oferty',
  latestCV: 'Najnowsze CV',
  totalValid: 'Liczba ważnych',
  totalExpired: 'Liczba nieważnych',
  totalAnonymizationRequested: 'Liczba żądań anonymizacji',
  selectGdprStatus: 'Wybierz status RODO',
  canNotCopy: 'Kopiowanie jest nie jest wspierane przez to urządzenie/przeglądarkę.',
  copied: 'Skopiowano',
  rowsPerPage: 'Wiersze na stronie',
  settingsJobs: 'Rekrutacje',
  settingsStaff: 'Pracownicy',
  includeCandidatesData: 'Uwzględnij dane kandydatów:',
  includeJobsData: 'Uwzględnij dane rekrutacji:',
  export: 'Eksport',
  updatedAt: 'Ostatnia aktualizacja',
  stepName: 'Nazwa kroku',
  candidateStatus: 'Status kandydata',
  candidateSource: 'Źródło kandydata',
  filesCount: 'Liczba załączników',
  jobStatus: 'Status rekrutacji',
  numberOfSteps: 'Liczba kroków',
  columnNameLanguage: 'Wybierz język kolumn',
  jobsCount: 'Liczba rekrutacji',
  exportAlert:
    'Wybierz dane do eksportu do formatu csv. UWAGA: System eksportuje wszystkie dane, niezależnie od wybranych w tabeli filtrów.',
  totalDemand: 'Całkowite zapotrzebowanie',
  toAssign: 'Do przypisania',
  client: 'Klient',
  project: 'Projekt',
  addressType: 'Typ adresu',
  house: 'Mieszkanie',
  roomsCount: 'Liczba pokoi',
  takenSeatsCount: 'Zajete miejsca',
  freeSeatsCount: 'Wolne miejsca',
  placesTotal: 'Wszystkie miejsca',
  include: 'Uwzględnij',
  filter: 'Filtr',
  dateRange: 'Zakres dat',
  allRecruiters: 'Wszyscy rekruterzy',
  deleteClientProjectInfo: 'Wszystkie połączone projekty zostaną usunięte.',
  edit: 'Edytuj',
  yes: 'Tak',
  no: 'Nie',
  deleteRoom: 'Usuń pokój',
  whenHired: 'Podczas zatrudniania',
  whenRejected: 'Podczas odrzucania',
  whenApplied: 'Po zaaplikowaniu',
  whenRenewingTalentPoolConsent: 'Podczas odnawiania zgody do bazy talentów',
  whenInvitingFromTalentPool: 'Podczas zapraszania do rekrutacji z bazy talentów',
  whenMovedToNextStep: 'Podczas przenoszenia do kolejnego kroku',
  applied: 'Zaaplikowano',
  renewTalentPoolConsent: 'Odnowiono zgodę do bazy talentów',
  createdAt: 'Data utworzenia',
  steps: 'Kroki',
  stepDayLimit: 'Limit dni{postfix}',
  admins: 'Administratorzy',
  recruiters: 'Rekruterzy',
  experts: 'Eksperci',
  confirmNewPassword: 'Potwierdź nowe hasło',
  notRequired: 'Nieobowiązkowe',
  days: 'Dni',
  emailTemplate: 'Szablon email',
  anyChangeHereWillNotAffectTheTemplate: 'Zmiany wprowdzone w tym miejscu nie wpłyną na szablon procesu',
  applyFormEmailTemplate: 'Email po zaaplikowaniu',
  consents: 'Zgody',
  addCandidateManuallyAlert:
    'Upewnij się, że kandydat przesłał zgodę na obie klauzule przed zaznaczeniem tego faktu w systemie.',
  russian: 'Rosyjski',
  totalJobs: 'Wszystkie rekrutacje',
  addNewJob: 'Dodaj nową rekrutację',
  profile: 'Profil',
  pleaseConfirmYourAccount: 'Potwierdź swoje konto',
  accountConfirmed: 'Konto potwierdzone',
  accountHasBeenAlreadyConfirmed: 'Konto zostało już potwierdzone',
  everythingDoneHere: 'Wszystko gotowe, proszę',
  signIn: 'Zaloguj',
  backToLogin: 'Powrót na stronę główną',
  adventureStartsHere: 'Tu zaczyna się przygoda',
  fromNowOnYourWork: 'Od teraz twoja praca będzie przyjemnością!',
  iAgreeTo: 'Przeczytałem i akceptuję',
  signUp: 'Utwórz konto',
  alreadyHaveAnAccount: 'Masz już konto?',
  signInInstead: 'Zaloguj się',
  pageNotFound: 'Strona nie została znaleziona',
  weCouldNotFind: 'nie mogliśmy znaleźć strony, której szukasz',
  backToHome: 'Powrót do logowania',
  forgotPassword: 'Zapomniałeś hasła?',
  sendResetLink: 'Wyślij email do resetowania hasła',
  setYourNewPassword: 'Stwórz nowe hasło',
  enterYourNewSuperSecurePassword: 'Wprowadź swoje nowe i bezpieczne hasło',
  linkExpired: 'Link wygasł',
  enterYourEmailResetPassword: 'Wpisz swój adres e-mail, a my wyślemy Ci instrukcje dotyczące resetowania hasła.',
  resendPasswordResetEmail: 'Wyślij ponownie email do resetowania hasła',
  welcomeTo: 'Witaj w',
  pleaseSingInToYourAccount: 'Zaloguj się do swojego konta i rozpocznij przygodę',
  newOnOurPlatform: 'Pierwszy raz na naszej platformie?',
  createAnAccount: 'Utwórz konto',
  youAreNotAuthorized: 'Nie masz uprawnień!',
  youDoNotHavePermission: 'Nie masz pozwolenia na dostęp do tej strony. Idź do domu!',
  somethingWentWrongResendConfirmationEmail:
    'Coś poszło nie tak. Prosimy spróbować później lub ponownie wysłać e-mail z linkiem aktywacyjnym.',
  assignmentPeriod: 'Okres przypisania',
  indefinitePeriod: 'Na czas nieokreślony',
  pleaseSelectTheEndDate: 'Proszę wybrać date zakończenia',
  end: 'Zakończ',
  freeSeats: 'Wolne miejsca',
  totalSeats: 'Liczba miejsc',

  // employeeId: 'Identyfikator pracownika',
  projectAssignmentData: 'Dane przypisania w projekcie',
  assignmentPeriodStart: 'Dzień rozpoczęcia projektu',
  assignmentPeriodEnd: 'Dzień zakończenia projektu',

  // Document
  employeeFirstName: 'Imię pracownika',
  employeeLastName: 'Nazwisko pracownika',
  employeeStatus: 'Status pracownika',
  employeeEmail: 'E-mail pracownika',
  employeePhoneNumber: 'Numer telefonu pracownika',
  employeeStartWorkingDate: 'Data rozpoczęcia pracy',
  employeeMartialStatus: 'Stan cywilny pracownika',
  employeeNationality: 'Narodowość pracownika',
  employeeCustomAddressName: 'Nazwa adresu własnego',
  employeeCustomAddressStreet: 'Ulica adresu własnego',
  employeeCustomAddressHouseNumber: 'Numer domu adresu własnego',
  employeeCustomAddressFlatNumber: 'Numer mieszkania adresu własnego',
  employeeCustomAddressCity: 'Miasto adresu własnego',
  employeeCustomAddressZipCode: 'Kod pocztowy adresu własnego',
  employeeCustomAddressState: 'Województwo adresu własnego',
  employeeCustomAddressCountry: 'Kraj adresu własnego',
  employeeCustomAddressDateStart: 'Rozpoczęcie zakwaterowania adresu własnego',
  employeeCustomAddressDateEnd: 'Zakończenie zakwaterowania adresu własnego',
  employeeCompanyAddressName: 'Nazwa adresu firmowego',
  employeeCompanyAddressStreet: 'Ulica adresu firmowego',
  employeeCompanyAddressHouseNumber: 'Numer domu adresu firmowego',
  employeeCompanyAddressFlatNumber: 'Numer mieszkania adresu firmowego',
  employeeCompanyAddressCity: 'Miasto adresu firmowego',
  employeeCompanyAddressZipCode: 'Kod pocztowy adresu firmowego',
  employeeCompanyAddressState: 'Województwo adresu firmowego',
  employeeCompanyAddressCountry: 'Kraj adresu firmowego',
  employeeCompanyAddressDateStart: 'Rozpoczęcie zakwaterowania adresu firmowego',
  employeeCompanyAddressDateEnd: 'Zakończenie zakwaterowania adresu firmowego',
  employeePosition: 'Pozycja pracownika',
  employeeProject: 'Projekt pracownika',
  employeeClientName: 'Nazwa klienta',
  employeeClientRegistrationName: 'Nazwa rejestrowa klienta',
  employeeClientStreet: 'Ulica klienta',
  employeeClientHouseNumber: 'Numer domu klienta',
  employeeClientFlatNumber: 'Numer mieszkania klienta',
  employeeClientCity: 'Miasto klienta',
  employeeClientZipCode: 'Kod pocztowy klienta',
  employeeClientState: 'Województwo klienta',
  employeeClientCountry: 'Kraj klienta',
  employeeProjectAssignmentDateStart: 'Data rozpoczęcia przydziału projektu',
  employeeProjectAssignmentDateEnd: 'Data zakończenia przydziału projektu',
  employeeId: 'Identyfikator pracownika',

  houseName: 'Nazwa mieszkania',
  houseStatus: 'Status mieszkania',
  houseStreet: 'Ulica mieszkania',
  houseHouseNumber: 'Numer domu',
  houseFlatNumber: 'Numer mieszkania',
  houseCity: 'Miasto',
  houseZipCode: 'Kod pocztowy',
  houseState: 'Województwo',
  houseCountry: 'Kraj',
  houseRoomsList: 'Lista pokoi',
  houseTotalPlaces: 'Całkowita liczba miejsc',
  houseFreePlaces: 'Wolne miejsca',
  houseTakenPlaces: 'Zajęte miejsca',
  houseOccupantsList: 'Lista mieszkańców',
  houseOccupantsCurrentList: 'Lista obecnych mieszkańców',
  houseOccupantsFutureList: 'Lista przyszłych mieszkańców',
  houseOccupantsPastList: 'Lista poprzednich mieszkańców',

  projectName: 'Nazwa projektu',
  projectStatus: 'Status projektu',

  positionsList: 'Lista stanowisk',
  missingAssignees: 'Brakujący pracownicy',
  assigneesList: 'Lista przydzielonych pracowników',

  clientStatus: 'Status klienta',
  clientStreet: 'Ulica klienta',
  clientHouseNumber: 'Numer domu klienta',
  clientFlatNumber: 'Numer mieszkania klienta',
  clientCity: 'Miasto klienta',
  clientZipCode: 'Kod pocztowy klienta',
  clientState: 'Województwo klienta',
  clientCountry: 'Kraj klienta',
  clientProjectsList: 'Lista projetków',

  candidateEmail: 'Email kandydata',
  candidatePhoneNumber: 'Numer telefonu kandydata',
  candidateJobTitle: 'Tytuł pracy kandydata',
  candidateCurrentStep: 'Aktualny etap kandydata',
  candidateCustomFields: 'Niestandardowe pola kandydata',

  companyTaxId: 'NIP firmy',
  companyContactEmail: 'Kontaktowy adres email firmy',
  companyStreet: 'Ulica firmy',
  companyHouseNumber: 'Numer domu firmy',
  companyFlatNumber: 'Numer mieszkania firmy',
  companyCity: 'Miasto firmy',
  companyZipCode: 'Kod pocztowy firmy',
  companyState: 'Województwo firmy',
  companyCountry: 'Kraj firmy',

  generateFromTemplate: 'Generuj z szablonu',
  selectDocumentTemplate: 'Wybierz szablon dokumentu',
  generatePreview: 'Generuj podgląd',
  createDocumentFor: 'Stwórz dokument dla {target}',
  customFieldsVariables: 'Zmienne własne pola',
  addNewDocumentTemplate: 'Dodaj nowy szablon dokumentu',
  documentTemplates: 'Szablon dokumentu',
  selectModule: 'Wybierz moduł',
  document: 'Dokument',
  notifyCandidateByEmail: 'Powiadom kandydata emailem',

  staffTags: 'Tagi pracowników',
  houseTags: 'Tagi mieszkań',
  projectTags: 'Tagi projektów',
  clientTags: 'Tagi klientów',

  job: 'Rekrutacja',
  jobSettings: 'Ustawienia rekrutacji',
  employeeSettings: 'Ustawienia pracowników',
  houseSettings: 'Ustawienia mieszkań',
  clientSettings: 'Ustawienia klientów',
  projectSettings: 'Ustawienia projektów',

  addCustomField: 'Dodaj pole własne',
  settingsHeader: 'Ustawienia',
  sharedCustomFieldJobsAlert:
    'Pola własne są współdzielone przez teczki kandydatów (w rekrutacjacj) oraz teczki pracowników. Zmiany dokonane w tym miejscu będą miały wpływ na dane w sekcji Pracownicy.',
  sharedCustomFieldEmployeesAlert:
    'Pola własne są współdzielone przez teczki kandydatów (w rekrutacjacj) oraz teczki pracowników. Zmiany dokonane w tym miejscu będą miały wpływ na dane w sekcji Rekrutacje.',
  sharedTagsJobsAlert:
    'Tagi są współdzielone przez teczki kandydatów (w rekrutacjacj) oraz teczki pracowników. Zmiany dokonane w tym miejscu będą miały wpływ na dane w sekcji Pracownicy.',
  sharedTagsEmployeesAlert:
    'Tagi są współdzielone przez teczki kandydatów (w rekrutacjacj) oraz teczki pracowników. Zmiany dokonane w tym miejscu będą miały wpływ na dane w sekcji Rekrutacje.',

  spanish: 'Hiszpański',
  selected: '{0} wybrane',
  update: 'Aktualizuj',
  upload: 'Prześlij',

  upgradeInformation: 'Ta funkcja dostępna jest w pakiecie Shine. Aby z niej skorzystać, wybierz wyższy plan usługi.',
  editAssignee: 'Edytuj pracownika',
  addNewPosition: 'Dodaj nowe stanowisko',
  assigneeData: 'Dane pracownika',
  assignmentDateStart: 'Rozpoczęcie pracy',
  assignmentDateEnd: 'Zakończenie pracy',
  futureCount: 'Planowane',
  pastCount: 'Zakończone',
  currentGroup: 'W toku',
  futureGroup: 'Planowane',
  pastGroup: 'Zakończone',
  backTo: 'Wróć do',
  totalClientsCardSubheader: 'Liczba wszystkich klientów',
  sumForAllPositionsSubheader: 'Liczba wszystkich stanowisk',
  sumForAllProjectsSubheader: 'Liczba wszystkich projektów',
  from: 'Od',
  to: 'Do',
  addAssignee: 'Przypisz pracownika',
  assigneesCurrentList: 'Pracownicy (W toku)',
  assigneesFutureList: 'Pracownicy (Zaplanowane)',
  assigneesPastList: 'Pracownicy (Zakończone)',
  hourlyRatesCategory: 'Stawki godzinowe',
  statuses: 'Statusy',
  addresses: 'Adresy',
  addAddress: 'Dodaj zakwaterowanie',
  noAddressOption: 'Brak adresu',
  customAddressOption: 'Własny',
  companyAddressOption: 'Firmowy',
  customAddressData: 'Dane mieszkania własnego',
  accommodationDateStart: 'Rozpoczęcia zakwaterowania',
  accommodationDateEnd: 'Zakończenie zakwaterowania',
  addressData: 'Dane mieszkania firmowego',
  addNewAddress: 'Dodaj zakwaterowanie',
  addNewRoom: 'Dodaj pokój',
  accommodationDemand: 'Liczba miejsc',
  accommodationWorkload: 'Obłożenie',
  occupants: 'Mieszkańcy',
  addOccupants: 'Dodaj mieszkańców',
  editRoomInformation: 'Edytuj dane pokoju',
  addNewOccupants: 'Dodaj mieszkańców',
  deleteOccupant: 'Usuń zakwaterowanie',
  currentAssignment: 'Obecne stanowiska',
  futureAssignment: 'Planowane stanowiska',
  pastAssignment: 'Zakończone stanowiska',
  currentOccupancy: 'Obecne zakwaterowania',
  futureOccupancy: 'Planowane zakwaterowania',
  pastOccupancy: 'Zakończone zakwaterowania',
  currentCustomAddress: 'Obecne adresy własne',
  futureCustomAddress: 'Planowane adresy własne',
  pastCustomAddress: 'Zakończone adresy własne',
  allAssignment: 'Wszystkie stanowiska',
  allOccupancy: 'Wszystkie zakwaterowania',
  allCustomAddress: 'Wszystkie adresy własne',
  assignment: 'Stanowisko',
  editOccupant: 'Edytuj dane mieszkańca',
  occupantData: 'Dane mieszkańca',
  addAssignment: 'Przypisz stanowisko',
  companyAddress: 'Mieszkanie firmowe',
  dayMonthSwitch: 'Dzień/Miesiąc',
  today: 'Dzisiaj',
  date: 'Data',
  report: 'Raport',
  dailyTimesheet: 'Dzienny czas pracy',
  sum: 'Suma',
  monthlyTimesheet: 'Miesięczny czas pracy',
  autoMode: 'Tryb automatyczny',
  timesheet: 'Ewidencja czasu',
  dailyDateRangeStart: 'Pierwszy dzień raportu',
  dailyDateRangeEnd: 'Ostatni dzień raportu',
  monthlyDateRangeStart: 'Pierwszy miesiąc raportu',
  monthlyDateRangeEnd: 'Ostatni miesiąc raportu',
  changingParametersBlocked: 'Zapisz zmiany, aby odblokować pozostałe opcje',
  positionName: 'Stanowisko',
  exportDateStart: 'Początek raportu',
  exportDateEnd: 'Koniec raportu',
  download: 'Pobierz',
  resource: 'Zasób',
  selectResource: 'Wybierz zasób',
  doneDate: 'Wykonano',
  hideDoneReminders: 'Ukryj wykonane',
  candidateOfferDetail: 'Kandydat',
}
