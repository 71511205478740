import { QueryTypes } from '@/store/core/actions'

export const getDashboardRecruitmentData = async ({ commit, dispatch }, { recruiters, dateRange }) => {
  const { success, data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/dashboard/recruitment/`,
      params: { recruiters, date_range: dateRange },
    },
    { root: true },
  )

  commit('SET_DASHBOARD_RECRUITMENT_DATA', data)

  return success
}

export const getDashboardTalentPoolData = async ({ commit, dispatch }) => {
  const { success, data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/dashboard/talent-pool/`,
    },
    { root: true },
  )

  commit('SET_DASHBOARD_TALENT_POOL_DATA', data)

  return success
}

export const getDashboardEmployeeData = async ({ commit, dispatch }, { dateRange }) => {
  const { success, data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/dashboard/employee/`,
      params: { date_range: dateRange },
    },
    { root: true },
  )

  commit('SET_DASHBOARD_EMPLOYEE_DATA', data)

  return success
}

export const getDashboardHouseData = async ({ commit, dispatch }) => {
  const { success, data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/dashboard/house/`,
    },
    { root: true },
  )

  commit('SET_DASHBOARD_HOUSE_DATA', data)

  return success
}

export const getDashboardProjectData = async ({ commit, dispatch }) => {
  const { success, data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/dashboard/project/`,
    },
    { root: true },
  )

  commit('SET_DASHBOARD_PROJECT_DATA', data)

  return success
}

export const getDashboardClientData = async ({ commit, dispatch }) => {
  const { success, data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/dashboard/client/`,
    },
    { root: true },
  )

  commit('SET_DASHBOARD_CLIENT_DATA', data)

  return success
}

export default {
  getDashboardRecruitmentData,
  getDashboardTalentPoolData,
  getDashboardEmployeeData,
  getDashboardHouseData,
  getDashboardProjectData,
  getDashboardClientData,
}
