import { mutationFactory } from 'vuex-factories'
import actions from './actions'

export default {
  namespaced: true,
  state: {
    currentJobs: [],
    workflows: [],
    offers: {
      draft: [],
      ongoing: [],
      finished: [],
    },
    waffles: [],
    candidatesOffers: [],
    jobs: [],

    // ---------------
    workflowTemplateData: {
      stepsTemplate: [],
    },
    jobData: { workflow: {} },
    jobCandidateData: {},
    emailTemplateData: {},
    defaultEmailTemplateData: {
      bodyHtml: '',
      subject: '',
    },
  },
  getters: {
    selectWorkflowTemplate: (state) => {
      return state.workflows.map((workflow) => {
        return { text: workflow.title, value: workflow }
      })
    },
    allWorkflows: (state) => {
      return state.workflows.sort((a, b) => +new Date(a.timestamp) - +new Date(b.timestamp)).reverse()
    },
    getOfferCandidates: (state) => (offerId) => {
      return state.candidatesOffers.filter((co) => co.o_id === offerId) || []
    },
    getWaffle: (state) => (candidateId) => {
      return state.waffles.find((w) => w.id === candidateId) || {}
    },

    /// New API
    getDraftJobs: (state) => () => {
      return state.jobs.filter((r) => r.stage === 'draft') ?? []
    },
    getOngoingJobs: (state) => () => {
      return state.jobs.filter((r) => r.stage === 'ongoing') ?? []
    },
    getFinishedJobs: (state) => () => {
      return state.jobs.filter((r) => r.stage === 'finished') ?? []
    },
  },
  actions,
  mutations: {
    ...mutationFactory({
      SET_CURRENT_JOBS: [{ key: 'currentJobs' }],
      SET_JOBS: [{ key: 'jobs' }],
      SET_WORKFLOW: [{ key: 'workflow' }],
      SET_WORKFLOW_TEMPLATE_DATA: [{ key: 'workflowTemplateData' }],
      SET_JOB_DATA: [{ key: 'jobData' }],
      SET_JOB_CANDIDATE_DATA: [{ key: 'jobCandidateData' }],
      SET_EMAIL_TEMPLATE_DATA: [{ key: 'emailTemplateData' }],
      SET_DEFAULT_EMAIL_TEMPLATE_DATA: [{ key: 'defaultEmailTemplateData' }],
    }),
  },
}
