import { mutationFactory } from 'vuex-factories'
import actions from './actions'

export default {
  namespaced: true,
  state: {
    employeeData: {
      files: [],
      languages: [],
    },
    houseData: {
      rooms: [],
    },
    roomData: {},
    projectData: {
      assignees: [],
    },
    positionData: {},
    clientData: {
      projects: [],
    },
  },
  getters: {},
  actions,
  mutations: {
    ...mutationFactory({
      SET_EMPLOYEE_DATA: [{ key: 'employeeData' }],
      SET_HOUSE_DATA: [{ key: 'houseData' }],
      SET_ROOM_DATA: [{ key: 'roomData' }],
      SET_PROJECT_DATA: [{ key: 'projectData' }],
      SET_POSITION_DATA: [{ key: 'positionData' }],
      SET_CLIENT_DATA: [{ key: 'clientData' }],
    }),
  },
}
