import { mdiCogOutline } from '@mdi/js'
import { PRIVATE } from '@/plugins/acl/const'

export default [
  {
    subheader: 'settingsHeader',
  },
  {
    title: 'settings',
    icon: mdiCogOutline,
    children: [
      {
        title: 'company',
        to: 'apps-company-view',
        resource: PRIVATE.SETTINGS.COMPANY,
        action: 'read',
      },
      {
        title: 'userManagement',
        to: 'apps-user-list',
        resource: PRIVATE.SETTINGS.USER_MANAGEMENT,
        action: 'read',
      },
      {
        title: 'jobs',
        to: 'page-jobs-settings',
        resource: PRIVATE.SETTINGS.JOBS,
        action: 'read',
      },
      {
        title: 'employees',
        to: 'page-employees-settings',
        resource: PRIVATE.SETTINGS.EMPLOYEES,
        action: 'read',
      },
      {
        title: 'houses',
        to: 'page-houses-settings',
        resource: PRIVATE.SETTINGS.HOUSES,
        action: 'read',
      },
      {
        title: 'clients',
        to: 'page-clients-settings',
        resource: PRIVATE.SETTINGS.CLIENTS,
        action: 'read',
      },
      {
        title: 'projects',
        to: 'page-projects-settings',
        resource: PRIVATE.SETTINGS.PROJECTS,
        action: 'read',
      },
    ],
  },
]
