import { mdiEmailOutline, mdiTimelineCheckOutline, mdiFileDocumentOutline } from '@mdi/js'
import { PRIVATE } from '@/plugins/acl/const'

export default [
  {
    subheader: 'templates',
  },
  {
    title: 'document',
    icon: mdiFileDocumentOutline,
    to: 'apps-document-templates-list',
    resource: PRIVATE.SHINE_FEATURES,
  },
  {
    title: 'workflow',
    icon: mdiTimelineCheckOutline,
    to: 'apps-workflow-templates-list',
  },
  {
    title: 'email',
    icon: mdiEmailOutline,
    to: 'apps-email-templates-list',
  },
]
