// axios
import axios from 'axios'
import Vue from 'vue'
import qs from 'qs'

const routerImport = null

// const routerImport = () => import('::op/router/index')
const storeImport = null

// const storeImport = () => import('::op/store/index')
const mutation = 'recruiter/RESET_RECRUITER'

// eslint-disable-next-line import/prefer-default-export
export const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  baseURL: '/api/v1/',
})

axiosIns.defaults.paramsSerializer = (params) => qs.stringify(params, { indices: false }) // param=value1&param=value2

axiosIns.interceptors.request.use(
  (config) => {
    // Do something before request is sent

    const accessToken = localStorage.getItem('accessToken')

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    return config
  },
  (error) => Promise.reject(error),
)

axiosIns.interceptors.response.use(
  (response) => {
    // Return a successful response back to the calling service
    return response
  },
  async (error) => {
    // Return any error which is not due to authentication back to the calling service
    if (error.response.status !== 401) {
      return new Promise((resolve, reject) => {
        reject(error)
      })
    }

    // Logout user if token refresh didn't work or user is disabled
    if (error.config.url === 'recruiter/login/refresh/' || error.response.data.detail === 'Token is blacklisted') {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('userData')
      localStorage.removeItem('userAbility')
      const store = await storeImport()
      const router = await routerImport()

      store.default.commit(mutation)
      await router.default.push({ name: 'Login' })

      return new Promise((resolve, reject) => {
        reject(error)
      })
    }

    if (error.response.data.detail === 'No active account found with the given credentials') {
      return new Promise((resolve, reject) => {
        reject(error)
      })
    }

    if (error.response.data.detail === 'Recruiter is not active') {
      return new Promise((resolve, reject) => {
        reject(error)
      })
    }

    if (error.response.data.detail === 'Authentication credentials were not provided.') {
      return new Promise((resolve, reject) => {
        reject(error)
      })
    }

    if (error.response.data.detail === 'Account is suspended, please contact with administrator of your organization') {
      return new Promise((resolve, reject) => {
        reject(error)
      })
    }

    // Try request again with new token
    const refreshToken = localStorage.getItem('refreshToken')
    if (refreshToken) {
      const { data } = await axiosIns.post('recruiter/login/refresh/', {
        refresh: refreshToken,
      })
      localStorage.setItem('accessToken', data.access)
    }
    const { config } = error
    if (localStorage.getItem('accessToken') !== null)
      config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`

    // eslint-disable-next-line no-return-await
    return await axios.request(config)
  },
)

Vue.prototype.$http = axiosIns

// export default axiosIns
