import { i18n } from '@/plugins/i18n'
import { QueryTypes } from '@/store/core/actions'
import { axiosIns } from '@/plugins/axios'

const loginWithEmailAPI = async ({ commit, dispatch }, { email, password }) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `recruiter/login/`,
      payload: { email, password },
    },
    {
      root: true,
    },
  )
  if (success) {
    localStorage.setItem('accessToken', data.access)
    localStorage.setItem('refreshToken', data.refresh)

    commit('app/SET_SUCCESS_SNACK', i18n.t('loggedIn'), {
      root: true,
    })

    return success
  }
  const response = data
  if (response.detail === 'Recruiter is not active') {
    commit('app/SET_ERROR_SNACK', 'Account is not active. Please confirm your account', {
      root: true,
    })

    return false
  }
  if (
    response.detail === 'No active account found with the given credentials' ||
    response.detail === 'Account is suspended, please contact with administrator of your organization'
  ) {
    commit('app/SET_ERROR_SNACK', response.detail, {
      root: true,
    })

    return false
  }

  commit('app/SET_ERROR_SNACK', 'Something went wrong', {
    root: true,
  })

  return false
}

const logout = async ({ commit, dispatch }) => {
  const { success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `recruiter/logout/`,
      payload: { refreshToken: localStorage.getItem('refreshToken') },
    },
    {
      root: true,
    },
  )
  if (success) {
    commit('app/SET_SUCCESS_SNACK', i18n.t('loggedOut'), {
      root: true,
    })
    commit('SET_RECRUITER', {
      user: {
        firstName: '',
        lastName: '',
      },
    })
  }

  return success
}

const getRecruiterProfile = async ({ commit, dispatch }) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `recruiter/profile/me/`,
    },
    {
      root: true,
    },
  )
  if (success) {
    // if (i18n.locale !== data.language) i18n.locale = data.language
    commit('SET_RECRUITER', data)

    return data
  }
}

const updateRecruiterProfile = async ({ commit, dispatch }, { id, user }) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `recruiter/profile/${id}/`,
      payload: { user },
    },
    {
      root: true,
    },
  )
  if (success) {
    // if (i18n.locale !== data.language) i18n.locale = data.language
    commit('SET_RECRUITER', data)
  }

  return { data, success }
}

const changeRecruiterProfilePassword = async ({ commit, dispatch }, { oldPassword, newPassword }) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `recruiter/change-password/`,
      payload: { oldPassword, newPassword },
    },
    {
      root: true,
    },
  )
  if (success) {
    return true
  }
  const response = data
  if (response.data?.oldPassword) {
    commit('app/SET_ERROR_SNACK', response.data.oldPassword, { root: true })

    return false
  }
  if (response.data?.nonFieldErrors) {
    commit('app/SET_ERROR_SNACK', response.data.nonFieldErrors.join('\n'), {
      root: true,
    })

    return false
  }
  commit('app/SET_ERROR_SNACK', i18n.t('somethingWentWrong'), {
    root: true,
  })

  return false
}

//
// const logoutRecruiter = async ({ commit }) => {
//   try {
//     await apiAxios.get('recruiter/logout/')
//     commit('SET_RECRUITER', {})
//     removeAllTokens()
//     commit('notification/SET_INFO_SNACK', i18n.t('notification.info.loggedOut'), {
//       root: true,
//     })
//   } catch (e) {
//     commit('notification/SET_ERROR_SNACK', i18n.t('wentWrong'), {
//       root: true,
//     })
//     console.error(e)
//   }
// }
//
const createRecruiterProfile = async ({ commit }, payload) => {
  try {
    await axiosIns.post('recruiter/create/', payload)
    commit('app/SET_SUCCESS_SNACK', 'Account created. Check your email and confirm account', {
      root: true,
    })

    return true
  } catch (e) {
    if (e.response.data.user?.nonFieldErrors) {
      commit('app/SET_ERROR_SNACK', e.response.data.user.nonFieldErrors.join('\n'), {
        root: true,
      })

      return false
    }
    if (e.response.data?.nonFieldErrors) {
      commit('app/SET_ERROR_SNACK', e.response.data.nonFieldErrors.join('\n'), {
        root: true,
      })

      return false
    }
    if (e.response.data.user?.email) {
      commit('app/SET_ERROR_SNACK', e.response.data.user.email.join('\n'), { root: true })

      return false
    }
    commit('app/SET_ERROR_SNACK', 'Something went wrong', { root: true })

    return false
  }
}

const sendRestRecruiterPasswordEmail = async ({ commit }, { email }) => {
  await axiosIns.post('password-reset/', {
    email,
  })
  commit('app/SET_SUCCESS_SNACK', 'Password reset link has been sent', { root: true })
}

const verifyPasswordResetToken = async ({ commit }, { token }) => {
  try {
    await axiosIns.post('password-reset/validate_token/', { token })

    return true
  } catch (e) {
    if (e.response.data.status === 'notfound') {
      commit('app/SET_INFO_SNACK', 'Reset password link has expired', { root: true })

      return false
    }
    commit('app/SET_INFO_SNACK', 'Something went wrong', { root: true })

    return false
  }
}

const resetPasswordRecruiterConfirm = async ({ commit }, { password, token }) => {
  try {
    await axiosIns.post(`password-reset/confirm/`, {
      password,
      token,
    })
    commit('app/SET_SUCCESS_SNACK', 'New password has been saved', { root: true })

    return true
  } catch (e) {
    if (e.response.data.password) {
      commit('app/SET_ERROR_SNACK', e.response.data.password.join('\n'), { root: true })

      return false
    }
    commit('app/SET_ERROR_SNACK', 'Something went wrong', { root: true })

    return false
  }
}

const resendRecruiterConfirmationEmail = async ({ dispatch }, email) => {
  await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `recruiter/resend-confirmation-email/`,
      payload: { email },
    },
    {
      root: true,
    },
  )
}

export default {
  loginWithEmailAPI,
  logout,
  createRecruiterProfile,
  sendRestRecruiterPasswordEmail,
  getRecruiterProfile,
  changeRecruiterProfilePassword,
  updateRecruiterProfile,
  verifyPasswordResetToken,
  resetPasswordRecruiterConfirm,
  resendRecruiterConfirmationEmail,
}
