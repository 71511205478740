import '@/@fake-db/db'
import Vue from 'vue'

import VueMasonry from 'vue-masonry-css'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

// import IdleVue from 'idle-vue'
import { i18n } from '@/plugins/i18n'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import '@/styles/mixins.scss'
import App from './App.vue'
import './plugins/acl'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// const EventBus = new Vue()

Vue.use(VueMasonry)

// Vue.use(IdleVue, {
//   eventEmitter: EventBus,
//   store,
//   idleTime: 600 * 5, // 10min
//   startAtIdle: false,
// })

if (process.env.VUE_APP_ENV === 'prod') {
  Sentry.init({
    Vue,
    dsn: 'https://4cb72fb1d30b43bb93011b5bf7d269fa@o762066.ingest.sentry.io/4504091749449728',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    release: `new-vision@${process.env.VUE_APP_VERSION}`,
  })
}

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
