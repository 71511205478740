import { axiosIns } from '@axios'
import { QueryTypes } from '@/store/core/actions'
import { i18n } from '@/plugins/i18n'

export const getTalentPoolEntries = async ({ dispatch }, { tags, gdprStatus, search, page, pageSize, ordering }) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/talent-pool/entries/`,
      params: {
        candidate__tags: tags,
        gdpr_status: gdprStatus,
        search,
        page,
        page_size: pageSize,
        ordering,
      },
    },
    {
      root: true,
    },
  )

  return { data, success }
}

export const getTalentPoolOverview = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/talent-pool/entries/overview/`,
    },
    { root: true },
  )

  return data
}

export const getTalentPoolEntryDetails = async ({ dispatch, commit }, talentPoolEntryId) => {
  const { success, data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/talent-pool/entry/${talentPoolEntryId}/`,
    },
    { root: true },
  )
  if (success) commit('SET_TALENT_POOL_ENTRY_DATA', data)

  return { success, data }
}

export const updateTalentPoolEntryDetails = async ({ dispatch, commit }, { id, payload }) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/talent-pool/entry/${id}/`,
      payload,
    },
    { root: true },
  )
  if (success) commit('SET_TALENT_POOL_ENTRY_DATA', data)

  return { data, success }
}

export const talentPoolEntryAutoAnonymizationDisabled = async ({ dispatch }, talentPoolId) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `company/talent-pool/entry/${talentPoolId}/withheld-from-deletion/`,
    },
    { root: true },
  )

  return data
}

export const talentPoolEntrySetForAnonymization = async ({ dispatch }, talentPoolId) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `company/talent-pool/entry/${talentPoolId}/set-for-deletion/`,
    },
    { root: true },
  )

  // TODO: true / false
  return !!data
}

export const talentPoolAcceptAnonymizationRequest = async ({ dispatch }, talentPoolId) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `company/talent-pool/entry/${talentPoolId}/accept-delete-request/`,
    },
    { root: true },
  )

  return data
}

export const talentPoolDeclineAnonymizationRequest = async ({ dispatch }, talentPoolId) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `company/talent-pool/entry/${talentPoolId}/decline-delete-request/`,
    },
    { root: true },
  )

  return data
}

export const getTalentPoolFilterChoices = async ({ dispatch }, { tags, gdprStatus, search }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/talent-pool/filter-choices/`,
      params: {
        candidate__tags: tags,
        gdpr_status: gdprStatus,
        search,
      },
    },
    { root: true },
  )

  return data
}

export const renewTalentPoolConsent = async ({ dispatch }, talentPoolId) => {
  const { data } = await dispatch('axiosQueryBuilder', {
    type: QueryTypes.POST,
    url: `company/talent-pool/entry/${talentPoolId}/renew-consent/`,
  })

  // TODO: true / false
  return !!data
}

export const signUpForTalentPool = async ({ dispatch }, payload) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `company/talent-pool/sign-up/`,
      payload,
    },
    { root: true },
  )

  // TODO: true / false
  return !!data
}

export const getInviteTalentPoolUrl = async ({ dispatch }, { lang, invitedBy }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/talent-pool/invite/link/`,
      params: {
        lang,
        invited_by: invitedBy,
      },
    },
    { root: true },
  )

  return data.inviteTalentPoolUrl
}

export const sendInvitationToJob = async ({ dispatch }, { talentPoolId, payload }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `company/talent-pool/entry/${talentPoolId}/send-invitation-to-job/`,
      payload,
    },
    { root: true },
  )

  // TODO: true / false
  return !!data
}

const inviteToJob = async ({ dispatch }, { jobId, payload }) => {
  await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `company/offer/${jobId}/invite/`,
      payload,
    },
    { root: true },
  )
}

// eslint-disable-next-line camelcase
export const exportTalentPoolEntries = async ({ commit }, { fields, column_names, stage }) => {
  try {
    const response = await axiosIns.get(`company/talent-pool/entries/export/`, {
      params: { fields, column_names, stage },
    })

    return {
      success: true,
      data: response.data,
      fileName: response.headers['content-disposition'].split('filename=')[1].split(';')[0],
    }
  } catch (e) {
    commit('app/SET_ERROR_SNACK', i18n.t('somethingWentWrong'), {
      root: true,
    })
    console.error(e)

    return { success: false }
  }
}

export const deleteTalentPoolEntryInternalNote = async ({ dispatch }, internalNoteId) => {
  const { success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.DELETE,
      url: `company/talent-pool/entry/internal-note/${internalNoteId}/`,
    },
    { root: true },
  )

  return { success }
}

export default {
  getTalentPoolEntries,
  getTalentPoolOverview,
  getTalentPoolEntryDetails,
  updateTalentPoolEntryDetails,
  talentPoolEntryAutoAnonymizationDisabled,
  talentPoolEntrySetForAnonymization,
  talentPoolAcceptAnonymizationRequest,
  talentPoolDeclineAnonymizationRequest,
  getTalentPoolFilterChoices,
  renewTalentPoolConsent,
  signUpForTalentPool,
  getInviteTalentPoolUrl,
  sendInvitationToJob,
  inviteToJob,
  exportTalentPoolEntries,

  deleteTalentPoolEntryInternalNote,
}
