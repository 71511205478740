import actions from './actions'
import mutations from './mutations'

export default {
  namespaced: true,
  state: {
    recruiter: {
      user: {
        firstName: '',
        lastName: '',
      },
    },
    currentUser: {
      emailVerified: null,
    },
  },
  actions,
  mutations,
}
