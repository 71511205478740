import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/plugins/acl/routeProtection'
import apps from './apps'
import dashboard from './dashboard'
import reminders from './reminders'
import pages from './pages'
import userInterface from './user-interface'
import store from '@/store/index'
import defineRules from '@/plugins/acl/config'
import { PRIVATE } from '@/plugins/acl/const'

Vue.use(VueRouter)

const routes = [
  // ? We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: '/',
    redirect: (to) => {
      const { access } = store.state.recruiter.recruiter

      // const userData = JSON.parse(localStorage.getItem('userData'))
      // const userRole = userData && userData.role ? userData.role : null

      if (['admin', 'recruiter'].includes(access)) return { name: 'dashboard' }
      if (access === 'expert') return { name: 'apps-ats-jobs-list' }

      return { name: 'auth-login', query: to.query }
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/Register.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/confirm-account/:id',
    name: 'auth-confirm-account',
    component: () => import('@/views/ConfirmAccount.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reset-password/',
    name: 'auth-reset-password',
    component: () => import(/* webpackChunkName: "ResetPassword" */ '@/views/ResetPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/add-new-platform/',
    name: 'add-new-platform',
    component: () => import(/* webpackChunkName: "AddNewPlatform" */ '@/views/utils/AddNewPlatform.vue'),
    meta: {
      layout: 'content',
      resource: PRIVATE.ADD_NEW_PLATFORM,
    },
    beforeEnter: (to, from, next) => {
      if (
        ['demo', 'staging', 'dev'].includes(process.env.VUE_APP_ENV) &&
        [
          'jk.demo.lightapply.com',
          'pw.demo.lightapply.com',
          'tr.demo.lightapply.com',
          'jk.staging.lightapply.com',
          'pw.staging.lightapply.com',
          'tr.staging.lightapply.com',
          'localhost',
          'jk.localhost',
        ].includes(window.location.host)
      ) {
        next()
      } else {
        next({ name: 'error-404', replace: true })
      }
    },
  },
  ...dashboard,
  ...reminders,
  ...userInterface,
  ...apps,
  ...pages,
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach(async (to, _, next) => {
  const isLoggedIn = localStorage.getItem('accessToken') && localStorage.getItem('userAbility')
  await store.dispatch('app/checkVersion')

  if (isLoggedIn && store.state.recruiter.recruiter.id == null) {
    await store.dispatch('recruiter/getRecruiterProfile')
    await store.dispatch('company/getSettings')
    const { rules } = defineRules(
      store.state.recruiter.recruiter.access,
      store.state.recruiter.recruiter.id,
      store.state.company.businessSettings,
    )
    router.app.$ability.update(rules)
    localStorage.setItem('userAbility', JSON.stringify(rules))
  }

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })

    // return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && !!isLoggedIn) {
    next('/')
  }

  return next()
})

export default router
