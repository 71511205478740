import { mdiViewDashboardVariantOutline } from '@mdi/js'
import { PRIVATE } from '@/plugins/acl/const'

export default [
  {
    title: 'dashboard',
    to: 'dashboard',
    icon: mdiViewDashboardVariantOutline,
    resource: PRIVATE.DASHBOARD,
    action: 'read',
  },
]
