export default {
  acceptRequest: 'Accept request',
  acceptedConsent: 'Accepted on ',
  access: 'Access',
  account: 'Account',
  actions: 'Actions',
  add: 'Add',
  addCandidate: 'Add candidate',
  addCategory: 'Add category',
  addClient: 'Add client',
  addCustomFiled: 'Add custom field',
  addFile: 'Add file',
  addHouse: 'Add house',
  addInternalNote: 'Add internal note',
  addOccupant: 'Add occupant',
  addPosition: 'Add position',
  addProject: 'Add project',
  addQuestion: 'Add question',
  addRejectReason: 'Add reject reason',
  addRejectReasonCategory: 'Add reject reason category',
  addReminder: 'Add reminder',
  addRoom: 'Add room',
  addSource: 'Add source',
  addStep: 'Add step',
  addTag: 'Add tag',
  addVariable: 'Add variable',
  additionalConsent: 'Additional Consents',
  additionalConsents: 'Additional Consents',
  address: 'Address',
  allCapacity: 'All capacity',
  allSeats: 'All places',
  anonymizationRequest: 'Anonymization request',
  requestAccepted: 'Anonymization accepted',
  requestDeclined: 'Anonymization declined',
  anonymizationRequestRemaining: 'Anonymization request remaining',
  anonymized: 'Anonymized',
  anotherAddress: 'Another address',
  apartments: 'Apartments',
  applyEmailTemplate: 'Apply email template',
  applyForm: 'Apply form',
  applyFormError: 'Missing GDPR clause and / or company registration name',
  applyFormLink: 'Apply form link',
  archived: 'Archived',
  assigned: 'Assigned',
  assignedTo: 'Assigned to',
  assignees: 'Assignees',
  attachedToFile: 'Attached to file',
  attachments: 'Attachments',
  attachmentsCount: 'Attachments - {sum} file(s)',
  attachmentsRequired: 'Attachments required',
  autoAnonymizationDisabled: 'Disable auto anonymization',
  back: 'Back',
  cancel: 'Cancel',
  candidateFirstName: 'Candidate first name',
  candidateLastName: 'Candidate last name',
  candidateStepFrom: 'Candidate step from',
  candidateStepTo: 'Candidate step to',
  candidates: 'Candidates',
  candidatesCounterCard: '{count} candidates',
  candidatesOnStageChart: 'Candidates on stage',
  candidatesOnStepChart: 'Candidates on step',
  candidatesSourcesChart: 'Candidates sources',
  cc: 'CC',
  charts: 'Charts',
  city: 'City',
  clear: 'Clear',
  clearButton: 'Clear filter',
  clientInformation: 'Client information',
  clientName: 'Client name',
  clientRegistrationName: 'Client registration name',
  clientStatuses: 'Client status',
  clients: 'Clients',
  close: 'Close',
  company: 'Company',
  companyDetails: 'Company details',
  companyName: 'Company name',
  companyRegistrationName: 'Company registration name',
  confirm: 'Confirm',
  confirmPassword: 'Confirm Password',
  confirmationModalDefaultEmail: 'Default email template will be used.',
  confirmationModalDefaultEmailConditions: 'Default email template will be send if subject or content remains empty.',
  confirmationModalUndoneQuestion: 'The operation cannot be undone. Are you sure?',
  confirmationModalUnsavedChanges: 'You have unsaved changes. Do you want to close without saving?',
  consent: 'Consent',
  contactConsent: 'Contact consent',
  contactEmail: 'Contact email',
  continue: 'Continue',
  copiedToClipboard: 'Copied to clipboard',
  copyToClipboard: 'Copy to clipboard',
  copyTooltip: 'Copy',
  country: 'Country',
  create: 'Create',
  createAccount: 'Create account',
  createAccountConsentAction: 'Check here to indicate that you have read and agree to the',
  addEmployee: 'Add employee',
  createJob: 'Create job',
  createdBy: 'Created by',
  current: 'Current',
  currentAddress: 'Current address',
  currentPassword: 'Current password',
  currentStep: 'Current step',
  customAddress: 'Custom address',
  customFields: 'Custom fields',
  dashboard: 'Dashboard',
  declineRequest: 'Decline request',
  declinedConsent: 'Declined on ',
  defaultApplied: 'Default when applied',
  defaultHired: 'Default when hired',
  defaultInviteFromTalentPool: 'Default when inviting to the job from the talent pool',
  defaultNextStep: 'Default when moved to next step',
  defaultReject: 'Default when reject',
  defaultRenewTalentPoolConsent: 'Default when renewing the talent pool consent',
  delete: 'Delete',
  deleteJob: 'Delete job',
  deleteOn: 'Delete on',
  deleteWorkflow: 'Delete workflow',
  demand: 'Demand',
  description: 'Description',
  details: 'Details',
  done: 'Done',
  dontShowWhoInvited: "Don't show who invited the candidate",
  draft: 'Draft',
  duplicate: 'Duplicate',
  editor: 'Editor',
  email: 'Email{postfix}',
  emailFormat: 'Email is incorrectly formatted',
  emailRequired: 'Email field required',
  emailSeparator: 'Emails must be separated by comma without space',
  employee: 'Employee',
  employeeStatuses: 'Employee status',
  employees: 'Employees',
  eng: 'English',
  expand: 'Expand',
  expired: 'Expired',
  field: 'Field',
  filePondIdle: "Drop file here or <span class='filepond--label-action'>click</span> to select them",
  fileReminders: 'File reminders',
  files: 'Files',
  filesWithoutType: 'Files without type',
  filterBy: 'Filter By',
  finished: 'Finished',
  firstName: 'First name{postfix}',
  firstNameRequired: 'First name*',
  flatNumber: 'Flat number',
  gdpr: 'GDPR',
  gdprStatus: 'GDPR status',
  generalReminders: 'General reminders',
  header: 'GDPR',
  hello: 'Hello, {name}',
  hideFullHouses: 'Hide full houses',
  hireCandidate: 'Hire candidate',
  hireOption: 'Hire selected',
  hired: 'Hired',
  hire: 'Hire',
  houseNumber: 'House number',
  houseStatuses: 'Apartment status',
  houses: 'Apartments',
  inProgress: 'In progress',
  incoming: 'Draft',
  information: 'Information',
  internalNoteCreatedByWithStep: '{createdBy} on {step} step',
  internalNoteCreatedByWoStep: '{createdBy}',
  internalNotes: 'Internal notes',
  invalidBulkCombination: 'Can not use any action with selected combination',
  invite: 'Invite',
  inviteJobRecruiterEmail: "Invite job - recruiter's email",
  inviteJobRecruiterLastName: "Invite job - recruiter's last name",
  inviteJobRecruiterName: "Invite job - recruiter's name",
  inviteJobTitle: 'Invite job - job title',
  inviteJobUrl: 'Invite to job url',
  inviteToJob: 'Invite to job',
  inviteUser: 'Invite user',
  invitedBy: 'Invited by',
  isInTalentPool: 'Is in talent pool',
  jobDeleted: 'Job deleted',
  jobDescription: 'Job description',
  jobDetails: 'Job details',
  jobHistory: 'Job history',
  jobTitle: 'Job title',
  jobs: 'Jobs',
  kids: 'Kids',
  language: 'Language',
  languages: 'Languages',
  lastName: 'Last name{postfix}',
  lastNameRequired: 'Last name*',
  latestAttachmentsCount: 'Latest attachments - {count} file(s)',
  latestJob: 'Latest job',
  localization: 'Localization',
  loggedIn: 'Logged in',
  loggedOut: 'Logged out',
  login: 'Login',
  logout: 'Logout',
  manageQuestions: 'Manage questions',
  manageSteps: 'Manage steps',
  maritialStatus: 'Marital status',
  maxSalary: 'Max salary',
  minSalary: 'Min salary',
  minimize: 'Minimize',
  moveToArchive:
    'Are you sure that you want to change this offer’s status to “archive”? This operation can’t be undone. You won’t be able to see this offer in the dashboard anymore.',
  moveToFinished:
    'Are you sure that you want to change this offer’s status to “finished”? While changing the job status into finished, we will reject all candidates which are on a new or in progress status and inform them about this fact.',
  moveToNextStep: 'Move to next step',
  moveToNextStepOption: 'Move selected to next step',
  moveToOngoing:
    'Are you sure that you want to change this offer’s status to “ongoing”? This operation can’t be undone. You won’t be able to see this offer in the dashboard anymore.',
  name: 'Name',
  national: 'National',
  nationalAddress: 'National address',
  nationality: 'Nationality',
  new: 'New',
  newPassword: 'New password',
  newVersion: 'New version of the system is available.',
  nextCandidate: 'Next candidate',
  noData: 'No data available',
  notAssigned: 'Not assigned',
  note: 'Note',
  places: 'Places',
  occupied: 'Taken',
  ongoing: 'Ongoing',
  organization: 'Organization',
  otherAvailableOccupants: 'Other available places',
  password: 'Password',
  personalData: 'Personal data',
  personalDataConsent: 'Personal data processing consent',
  personalDataDeleteRetention: 'Personal data delete retention',
  personalDataDeleteRetentionHint:
    'How long can you process the data since the Anonymization request was accepted, in days',
  personalDataProcessingPeriod: 'Personal data processing period',
  personalDataProcessingPeriodHint: 'How long can you process the data since the consent was given, in months',
  phoneLength: 'Max 31 characters',
  phoneNumber: 'Phone number',
  phoneNumberRequired: 'Phone number field required',
  pl: 'Polish',
  position: 'Position',
  positions: 'Positions',
  positionsAmount: 'Positions amount',
  positiveOnlyNumber: 'Value must be equal or higher than 0',
  preferredCity: 'Preferred city',
  preferredCityRequired: 'Preferred city field required',
  preferredCityVisible: 'Preferred city field visible',
  preview: 'Preview',
  previousCandidate: 'Previous candidate',
  privacyPolicy: 'Privacy policy',
  projectStatuses: 'Project status',
  projects: 'Projects',
  projectsAmount: 'Projects amount',
  question: 'Question',
  questions: 'Questions',
  recruiterEmail: 'Recruiter email',
  recruiterFirstName: 'Recruiter first name',
  recruiterLastName: 'Recruiter last name',
  refresh: 'Refresh',
  registrationName: 'Registration name',
  reject: 'Reject',
  rejectCandidate: 'Reject candidate',
  rejectOption: 'Reject selected',
  rejectReason: 'Reject reason',
  rejectReasonCategory: 'Reject reason category',
  rejectReasons: 'Reject reasons',
  rejected: 'Rejected',
  remindDate: 'Remind date',
  reminders: 'Reminders',
  renewConsentUrl: 'Renew consent url',
  required: 'Required',
  resendConfirmationEmail: 'Resend confirmation email',
  resetPassword: 'Reset password',
  rooms: 'Rooms',
  ru: 'Russian',
  save: 'Save',
  search: 'Search',
  searchInput: 'What are you looking for?',
  selectEmail: 'Select email',
  selectJob: 'Select job',
  selectRecruiter: 'Select recruiter',
  selectRejectReason: 'Select reject reason',
  selectSource: 'Select source',
  selectWorkflow: 'Select workflow',
  sendMeCopy: 'Send me a copy',
  sendTestEmail: 'Send test email',
  separateByComma: 'Emails should be separated by comma',
  setForAnonymization: 'Set for anonymization',
  settings: 'Settings',
  sharedWith: 'Shared with',
  showJobDescription: 'Show job description',
  showTalentPoolConsent: 'Show talent pool consent',
  somethingWentWrong: 'Something went wrong',
  source: 'Source',
  sourceCategory: 'Source category',
  sources: 'Source',
  staff: 'Staff',
  stage: 'Stage',
  state: 'State',
  status: 'Status{postfix}',
  step: 'Step',
  stepsCount: 'Steps: {count}',
  street: 'Street',
  subject: 'Subject',
  tag: 'Tag',
  tags: 'Tags',
  takenOccupants: 'Taken places',
  takenSeats: 'Taken places',
  talentPool: 'Talent pool',
  talentPoolConsents: 'Talent pool consents',
  talentPoolProcessingPeriod: 'Talent pool processing period',
  talentPoolProcessingPeriodHint: 'How long can you process the data since the consent was given, in months',
  talentPoolRenewEmail: "Send email to renew the consent before it's end",
  talentPoolRenewEmailTemplate: 'Renew email template',
  talentPoolRetention: 'Talent pool consent retention time (months)',
  taxId: 'Tax ID',
  templates: 'Templates',
  termsAndConditions: 'Terms and conditions',
  testEmail: 'Test email',
  title: 'Title',
  type: 'Type',
  undo: 'Undo',
  updatePassword: 'Update password',
  updateProfile: 'Update profile',
  url: 'Url',
  usedEmailVariables: 'Used email variables',
  userManagement: 'User management',
  valid: 'Valid',
  validUntil: 'Valid until',
  workflow: 'Workflow',
  workflowTemplateDeleted: 'Workflow template deleted',
  workingStartDay: 'Working start day',
  workload: 'Workload',
  zip: 'Zip code',
  selectTag: 'Select tag',
  selectStatus: 'Select status',
  selectLanguage: 'Select language',
  startWorkingDay: 'Start working day',
  martialStatus: 'Martial status',
  room: 'Room',
  removeReminder: 'Remove reminder',
  removeFile: 'Remove file',
  discard: 'Discard',
  user: 'User',
  editEmployeeInformation: 'Edit employee information',
  permanentResidence: 'Permanent residence',
  reminder: 'Reminder',
  addNewFile: 'Add new file',
  typeYourNote: 'Type your note',
  send: 'Send',
  removeFileReminder: 'Remove file reminder',
  total: 'Total',
  searchAndFilter: 'Search & Filter',
  seatsCounter: 'Seats counter',
  selectCity: 'Select city',
  addNewClient: 'Add new client',
  deleteClient: 'Delete client',
  positionAmount: 'Position amount',
  editClientInformation: 'Edit client information',
  projectsWorkload: 'Projects workload',
  assignee: 'Assignee',
  addNewHouse: 'Add new house',
  viewDetails: 'View details',
  free: 'Free',
  selectClient: 'Select client',
  addNewProject: 'Add new project',
  view: 'View',
  basicData: 'Basic data',
  zipCode: 'Zip code',
  takenAssignees: 'Taken assignees',
  board: 'Board',
  newCandidates: 'New candidates',
  incomingCandidates: 'Incoming candidates',
  employeeStats: 'Employee stats',
  newEmployees: 'New employees',
  totalEmployees: 'Total employees',
  sumAllEmployees: 'Sum all employees',
  employeesWithoutStatus: 'Employees without status',
  sumAllEmployeesWithoutStatus: 'Sum all employees without status',
  houseStatusOverview: 'House status overview',
  houseCountedStatuses: 'House counted statuses',
  projectStatusOverview: 'Project status overview',
  projectCountedStatuses: 'Project counted statuses',
  totalPositions: 'Total positions',
  clientCountedStatuses: 'Client counted statuses',
  clientStatusOverview: 'Client status overview',
  levelOfEmployment: 'Level of employment',
  totalClients: 'Total clients',
  totalProjects: 'Total projects',
  candidatesStats: 'Candidates’ stats',
  topSources: 'Top sources',
  candidatesSources: 'Candidates’ sources',
  candidatesFromSources: 'Candidates from sources',
  candidatesRejectReasons: 'Candidates’ reject reasons',
  topReasons: 'Top reasons',
  newUnhandledCandidates: 'New unhandled candidates',
  noActionForXDays: 'no action for {daysInStep} days',
  projectEmployment: 'Project employment',
  upcomingReminders: 'Upcoming reminders',
  accommodationStats: 'Accommodation stats',
  accommodationUsage: 'Accommodation usage',
  occupiedByEmployees: 'Occupied by employees',
  occupiedByOthers: 'Occupied by others',
  available: 'Available',
  projectWarnings: 'Project warnings',
  talentPoolStats: 'Talent pool stats',
  allNonAnonymizedEntries: 'All non-anonymized entries',
  anonymizationRequested: 'Anonymization requested',
  basicSettings: 'Basic settings',
  applyFormSettings: 'Apply form settings',
  applyFormLanguage: 'Apply form language',
  action: 'Action',
  candidatesInOngoingJobs: 'Candidates in ongoing jobs',
  totalCandidates: 'Total candidates',
  selectAssignedTo: 'Select assigned to',
  addJob: 'Add job',
  defaultEmailTemplates: 'Default Email templates',
  emailTemplates: 'Email templates',
  addNewEmailTemplate: 'Add new email template',
  polish: 'Polish',
  english: 'English',
  emailTo: 'Email to',
  variables: 'Variables',
  internalNote: 'Internal note',
  workflowTemplates: 'Workflow templates',
  addNewWorkflowTemplate: 'Add new workflow template',
  deleteStep: 'Delete step',
  apartmentStatuses: 'Apartment statuses',
  addStatus: 'Add status',
  active: 'Active',
  pending: 'Pending',
  recruiter: 'Recruiter',
  expert: 'Expert',
  admin: 'Admin',
  suspended: 'Suspended',
  overview: 'Overview',
  security: 'Security',
  jobList: 'Job list',
  candidatesAmount: 'Candidates amount',
  ensureThatTheseRequirementsAreMet: 'Ensure that these requirements are met',
  minimumCharacterLongUppercaseAndSymbol: 'Minimum 8 characters long, uppercase and special character',
  changePassword: 'Change password',
  jobsFinished: 'Jobs finished',
  jobsOngoing: 'Jobs ongoing',
  role: 'Role{postfix}',
  newCandidatesAmount: '{amount} new candidate(s)',
  extraData: 'Extra data',
  file: 'File',
  jobStage: 'Job status',
  latestCV: 'Latest CV',
  totalValid: 'Total valid',
  totalExpired: 'Total expired',
  totalAnonymizationRequested: 'Total anonymization requested',
  selectGdprStatus: 'Select GDPR status',
  canNotCopy: "Copy isn't supported on this device/browser.",
  copied: 'Copied Successfully',
  rowsPerPage: 'Rows per page',
  settingsJobs: 'Jobs',
  settingsStaff: 'Staff',
  includeCandidatesData: "Include candidate's data:",
  includeJobsData: "Include job's data:",
  export: 'Export',
  updatedAt: 'Updated at',
  stepName: 'Step name',
  candidateStatus: 'Candidate status',
  candidateSource: 'Candidate source',
  filesCount: 'Files count',
  jobStatus: 'Job status',
  numberOfSteps: 'Number of steps',
  columnNameLanguage: 'Column name language',
  jobsCount: 'Number of jobs',
  exportAlert:
    'Select data fields to be exported in a CSV file. CAUTION: The system will export all of the data, regardless of the filters selected previously.',
  totalDemand: 'Total demand',
  toAssign: 'To assign',
  client: 'Client',
  project: 'Project',
  addressType: 'Address type',
  house: 'House',
  roomsCount: 'Room number',
  takenSeatsCount: 'Taken places',
  freeSeatsCount: 'Free places',
  placesTotal: 'Total places',
  include: 'Include',
  filter: 'Filter',
  dateRange: 'Date range',
  allRecruiters: 'All recruiters',
  deleteClientProjectInfo: 'All connected projects will be deleted.',
  edit: 'Edit',
  yes: 'Yes',
  no: 'No',
  deleteRoom: 'Delete room',
  whenHired: 'When hired',
  whenRejected: 'When rejected',
  whenApplied: 'When applied',
  whenRenewingTalentPoolConsent: 'When renewing the talent pool consent',
  whenInvitingFromTalentPool: 'When inviting to the job from the talent pool',
  whenMovedToNextStep: 'When moved to next step',
  applied: 'Applied',
  renewTalentPoolConsent: 'Renew talent pool consent',
  createdAt: 'Created at',
  steps: 'Steps',
  stepDayLimit: 'Step day limit{postfix}',
  admins: 'Admins',
  recruiters: 'Recruiters',
  experts: 'Experts',
  confirmNewPassword: 'Confirm new password',
  notRequired: 'Not required',
  days: 'Days',
  emailTemplate: 'Email template',
  anyChangeHereWillNotAffectTheTemplate: 'Any change here will not affect the template',
  applyFormEmailTemplate: 'Apply form email template',
  consents: 'Consents',
  addCandidateManuallyAlert:
    'Make sure that the candidate agreed for both clauses by email, before checking them in the system.',
  russian: 'Russian',
  totalJobs: 'Total jobs',
  addNewJob: 'Add new job',
  profile: 'Profile',
  pleaseConfirmYourAccount: 'Please confirm your account',
  accountConfirmed: 'Account confirmed',
  accountHasBeenAlreadyConfirmed: 'Account has been already confirmed',
  everythingDoneHere: 'Everything done here, please',
  signIn: 'Sign in',
  backToLogin: 'Back to login',
  adventureStartsHere: 'Adventure starts here',
  fromNowOnYourWork: 'From now on your work will be a pleasure!',
  iAgreeTo: 'I agree to',
  signUp: 'Sign Up',
  alreadyHaveAnAccount: 'Already have an account?',
  signInInstead: 'Sign in instead',
  pageNotFound: 'Page Not Found',
  weCouldNotFind: "we couldn't find the page you are looking for",
  backToHome: 'Back to home',
  forgotPassword: 'Forgot Password?',
  sendResetLink: 'Send reset email',
  setYourNewPassword: 'Set your new password',
  enterYourNewSuperSecurePassword: 'Enter your new super secure passwords',
  linkExpired: 'Link expired',
  enterYourEmailResetPassword: "Enter your email and we'll send you instructions to reset your password",
  resendPasswordResetEmail: 'Resend password reset email',
  welcomeTo: 'Welcome to',
  pleaseSingInToYourAccount: 'Please sign-in to your account and start the adventure',
  newOnOurPlatform: 'New on our platform?',
  createAnAccount: 'Create an account',
  youAreNotAuthorized: 'You are not authorized!',
  youDoNotHavePermission: 'You don’t have permission to access this page. Go Home!',
  somethingWentWrongResendConfirmationEmail:
    'Something went wrong. Please try again later or resend confirmation email.',
  assignmentPeriod: 'Assignment period',
  indefinitePeriod: 'Indefinite period',
  pleaseSelectTheEndDate: 'Please select the end date',
  end: 'End',
  freeSeats: 'Free places',
  totalSeats: 'Total places',

  // employeeId: 'Employee’s ID',
  projectAssignmentData: 'Project assignment data',
  assignmentPeriodStart: 'Assignment period start',
  assignmentPeriodEnd: 'Assignment period end',

  // Document
  employeeFirstName: 'Employee first name',
  employeeLastName: 'Employee last name',
  employeeStatus: 'Employee status',
  employeeEmail: 'Employee email',
  employeePhoneNumber: 'Employee phone number',
  employeeStartWorkingDate: 'Employee start working date',
  employeeMartialStatus: 'Employee martial status',
  employeeNationality: 'Employee nationality',
  employeeCustomAddressName: 'Custom address name',
  employeeCustomAddressStreet: 'Custom address street',
  employeeCustomAddressHouseNumber: 'Custom address house number',
  employeeCustomAddressFlatNumber: 'Custom address flat number',
  employeeCustomAddressCity: 'Custom address city',
  employeeCustomAddressZipCode: 'Custom address zip code',
  employeeCustomAddressState: 'Custom address state',
  employeeCustomAddressCountry: 'Custom address country',
  employeeCustomAddressDateStart: 'Custom address date start',
  employeeCustomAddressDateEnd: 'Custom address date end',
  employeeCompanyAddressName: 'Company address name',
  employeeCompanyAddressStreet: 'Company address street',
  employeeCompanyAddressHouseNumber: 'Company address house number',
  employeeCompanyAddressFlatNumber: 'Company address flat number',
  employeeCompanyAddressCity: 'Company address city',
  employeeCompanyAddressZipCode: 'Company address zip code',
  employeeCompanyAddressState: 'Company address state',
  employeeCompanyAddressCountry: 'Company address country',
  employeeCompanyAddressDateStart: 'Company address date start',
  employeeCompanyAddressDateEnd: 'Company address date end',
  employeePosition: 'Employee position',
  employeeProject: 'Employee project',
  employeeClientName: 'Employee client name',
  employeeClientRegistrationName: 'Employee client registration name',
  employeeClientStreet: 'Employee client street',
  employeeClientHouseNumber: 'Employee client house number',
  employeeClientFlatNumber: 'Employee client flat number',
  employeeClientCity: 'Employee client city',
  employeeClientZipCode: 'Employee client zip code',
  employeeClientState: 'Employee client state',
  employeeClientCountry: 'Employee client country',
  employeeProjectAssignmentDateStart: 'Employee project assignment date start',
  employeeProjectAssignmentDateEnd: 'Employee project assignment date end',
  employeeId: 'Employee id',

  houseName: 'House name',
  houseStatus: 'House status',
  houseStreet: 'House street',
  houseHouseNumber: 'House number',
  houseFlatNumber: 'House flat number',
  houseCity: 'House city',
  houseZipCode: 'House zip code',
  houseState: 'House state',
  houseCountry: 'House country',
  houseRoomsList: 'Rooms list',
  houseTotalPlaces: 'Total places',
  houseFreePlaces: 'Free places',
  houseTakenPlaces: 'Taken places',
  houseOccupantsList: 'All occupants list',
  houseOccupantsCurrentList: 'Current occupants list',
  houseOccupantsFutureList: 'Future occupants list',
  houseOccupantsPastList: 'Past occupants list',

  projectName: 'Project name',
  projectStatus: 'Project status',

  positionsList: 'Positions list',
  missingAssignees: 'Missing assignees',
  assigneesList: 'Assignees list',

  clientStatus: 'Client status',
  clientStreet: 'Client street',
  clientHouseNumber: 'Client house number',
  clientFlatNumber: 'Client flat number',
  clientCity: 'Client city',
  clientZipCode: 'Client zip Code',
  clientState: 'Client state',
  clientCountry: 'Client country',
  clientProjectsList: 'Projects List',

  candidateEmail: 'Candidate email',
  candidatePhoneNumber: 'Candidate phone number',
  candidateJobTitle: 'Candidate job title',
  candidateCurrentStep: 'Candidate current step',
  candidateCustomFields: 'Candidate custom fields',

  companyTaxId: 'Company tax ID',
  companyContactEmail: 'Company contact email',
  companyStreet: 'Company street',
  companyHouseNumber: 'Company house number',
  companyFlatNumber: 'Company flat number',
  companyCity: 'Company city',
  companyZipCode: 'Company zip code',
  companyState: 'Company state',
  companyCountry: 'Company country',

  generateFromTemplate: 'Generate from template',
  selectDocumentTemplate: 'Select document template',
  generatePreview: 'Generate preview',
  createDocumentFor: 'Create document for {target}',
  customFieldsVariables: 'Custom fields variables',
  addNewDocumentTemplate: 'Add new document template',
  documentTemplates: 'Document templates',
  selectModule: 'Select module',
  document: 'Document',
  notifyCandidateByEmail: 'Notify candidate by email',

  staffTags: 'Staff tags',
  houseTags: 'House tags',
  projectTags: 'Project tags',
  clientTags: 'Client tags',

  job: 'Job',
  jobSettings: 'Job settings',
  employeeSettings: 'Employee settings',
  houseSettings: 'House settings',
  clientSettings: 'Client settings',
  projectSettings: 'Project settings',

  addCustomField: 'Add custom field',
  settingsHeader: 'Settings',
  sharedCustomFieldJobsAlert:
    'Custom fields are shared by candidate records (in recruitment) and employee records. Changes made here will affect the data in the Employees section.',
  sharedCustomFieldEmployeesAlert:
    'Custom fields/Tag are shared by candidate records (in recruitment) and employee records. Changes made here will affect the data in the Jobs section.',
  sharedTagsJobsAlert:
    'Tags are shared by candidate records (in recruitment) and employee records. Changes made here will affect the data in the Employees section.',
  sharedTagsEmployeesAlert:
    'Tags are shared by candidate records (in recruitment) and employee records. Changes made here will affect the data in the Jobs section.',

  spanish: 'Spanish',
  selected: '{0} selected',
  update: 'Update',
  upload: 'Upload',

  upgradeInformation: 'This feature is available in the Shine package. To use it, choose a higher service plan.',
  editAssignee: 'Edit assignee',
  addNewPosition: 'Add new position',
  assigneeData: "Assignee's data",
  assignmentDateStart: 'Assignment start date',
  assignmentDateEnd: 'Assignment end date',
  futureCount: 'Planned',
  pastCount: 'Finished',
  currentGroup: 'In progress',
  futureGroup: 'Planned',
  pastGroup: 'Finished',
  backTo: 'Back to',
  totalClientsCardSubheader: 'Sum of all clients',
  sumForAllPositionsSubheader: 'Sum of all positions',
  sumForAllProjectsSubheader: 'Sum of all projects',
  from: 'From',
  to: 'To',
  addAssignee: 'Add assignee',
  assigneesCurrentList: 'Employees (In progress)',
  assigneesFutureList: 'Employees (Planned)',
  assigneesPastList: 'Employees (Finished)',
  hourlyRatesCategory: 'Hourly rates',
  statuses: 'Statuses',
  addresses: 'Addresses',
  addAddress: 'Add accommodation',
  noAddressOption: 'No address',
  customAddressOption: 'Custom',
  companyAddressOption: 'Company',
  accommodationDateStart: 'Accommodation start date',
  accommodationDateEnd: 'Accommodation end date',
  customAddressData: 'Custom address data',
  addressData: 'Company address data',
  addNewAddress: 'Add accommodation',
  addNewRoom: 'Add room',
  accommodationDemand: 'Capacity',
  accommodationWorkload: 'Occupancy',
  occupants: 'Occupants',
  addOccupants: 'Add occupants',
  editRoomInformation: 'Edit room information',
  addNewOccupants: 'Add occupants',
  deleteOccupant: 'Delete accommodation',
  currentAssignment: 'Current assignments',
  futureAssignment: 'Planned assignments',
  pastAssignment: 'Finished assignments',
  currentOccupancy: 'Current accommodations',
  futureOccupancy: 'Planned accommodations',
  pastOccupancy: 'Finished accommodations',
  currentCustomAddress: 'Current custom addresses',
  futureCustomAddress: 'Planned custom addresses',
  pastCustomAddress: 'Finished custom addresses',
  allAssignment: 'All assignments',
  allOccupancy: 'All accommodations',
  allCustomAddress: 'All custom addresses',
  assignment: 'Assignment',
  editOccupant: 'Edit occupant data',
  occupantData: 'Occupant data',
  addAssignment: 'Add assignment',
  companyAddress: 'Company address',
  dayMonthSwitch: 'Day/Month',
  today: 'Today',
  date: 'Date',
  report: 'Report',
  dailyTimesheet: 'Daily timesheet',
  sum: 'Sum',
  monthlyTimesheet: 'Monthly timesheet',
  autoMode: 'Auto mode',
  timesheet: 'Timesheet',
  dailyDateRangeStart: 'Report starting day',
  dailyDateRangeEnd: 'Report end day',
  monthlyDateRangeStart: 'Report starting month',
  monthlyDateRangeEnd: 'Report end month',
  changingParametersBlocked: 'Save changes to unlock other options',
  positionName: 'Position',
  exportDateStart: 'Export start',
  exportDateEnd: 'Export end',
  download: 'Download',
  resource: 'Resource',
  selectResource: 'Select resource',
  doneDate: 'Done',
  hideDoneReminders: 'Hide done',
  candidateOfferDetail: 'Candidate',
}
