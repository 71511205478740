<template>
  <v-menu offset-y left nudge-bottom="14" min-width="230" content-class="user-profile-menu-content">
    <template v-slot:activator="{ on, attrs }">
      <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
        <v-avatar size="40px" v-bind="attrs" color="primary" class="v-avatar-light-bg primary--text" v-on="on">
          <v-img v-if="recruiter.avatar" :src="recruiter.avatar"></v-img>
          <v-icon v-else color="primary" size="28">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
          <v-avatar size="40px" color="primary" class="v-avatar-light-bg primary--text">
            <v-img v-if="recruiter.avatar" src="@/assets/images/avatars/1.png"></v-img>
            <v-icon v-else color="primary" size="28">
              {{ icons.mdiAccountOutline }}
            </v-icon>
          </v-avatar>
        </v-badge>
        <div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align: middle">
          <span class="text--primary font-weight-semibold mb-n1">
            {{ `${recruiter.user.firstName} ${recruiter.user.lastName}` }}
          </span>
          <small class="text--disabled text-capitalize">{{ recruiter.access }}</small>
        </div>
      </div>

      <v-divider></v-divider>

      <!-- Profile -->
      <v-list-item :to="{ name: 'page-account-settings' }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-t="'profile'" />
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="$can(ACTIONS.READ, PRIVATE.SETTINGS.COMPANY)" :to="{ name: 'apps-company-view' }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiDomain }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-t="'company'" />
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-2"></v-divider>

      <!-- Logout -->
      <v-list-item @click="logoutUser">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-t="'logout'" />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiDomain,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'
import { useRouter } from '@core/utils'
import { getCurrentInstance } from '@vue/composition-api'
import { useActions, useState } from 'vuex-composition-helpers'
import { initialAbility } from '@/plugins/acl/config'
import { PRIVATE, ACTIONS } from '@/plugins/acl/const'

export default {
  setup() {
    const vm = getCurrentInstance().proxy
    const { router } = useRouter()

    const { recruiter } = useState('recruiter', ['recruiter'])
    const { logout } = useActions('recruiter', ['logout'])

    const logoutUser = async () => {
      // Remove userData from localStorage
      // ? We just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      if (await logout()) {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')

        // Remove userData & Ability from localStorage
        localStorage.removeItem('userData')
        localStorage.removeItem('userAbility')

        // Reset ability
        vm.$ability.update(initialAbility)

        // Redirect to login page
        router.push({ name: 'auth-login' })
      }
    }

    return {
      logoutUser,
      recruiter,

      ACTIONS,
      PRIVATE,

      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiDomain,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
