import { subject as subjectAbility } from '@casl/ability'
import { axiosIns } from '@/plugins/axios'
import { QueryTypes } from '@/store/core/actions'
import { PRIVATE } from '@/plugins/acl/const'
import { i18n } from '@/plugins/i18n'

export const getCurrentJobs = async ({ dispatch }) => {
  // Check hideSuccessSnack
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: 'company/offers/my-current-offers/',
    },
    {
      root: true,
    },
  )

  return data
}

export const createJob = async ({ dispatch }, { payload }) => {
  // Check hideSuccessSnack
  await dispatch(
    'core/axiosQueryBuilder',
    { type: QueryTypes.POST, url: 'company/offer/create/', payload },
    {
      root: true,
    },
  )
}

export const updatedJob = async ({ commit }, { jobId, payload }) => {
  try {
    await axiosIns.put(`company/offer/${jobId}/`, {
      ...payload,
    })
    commit('notification/SET_SUCCESS_SNACK', i18n.t('done'), {
      root: true,
    })
  } catch (e) {
    if (e.response.data?.error) {
      commit('notification/SET_ERROR_SNACK', e.response.data?.error, {
        root: true,
      })
    } else {
      commit('notification/SET_ERROR_SNACK', i18n.t('somethingWentWrong'), {
        root: true,
      })
    }
    console.error(e)
  }
}

export const updatedJobPart = async ({ dispatch, commit }, { jobId, payload }) => {
  // Check hideSuccessSnack
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    { type: QueryTypes.PATCH, url: `company/offer/${jobId}/`, payload },
    {
      root: true,
    },
  )
  commit('SET_JOB_DATA', subjectAbility(PRIVATE.ATS.JOB, data))

  return { success }
}

export const deleteJob = async ({ dispatch }, jobId) => {
  // Check hideSuccessSnack
  await dispatch(
    'core/axiosQueryBuilder',
    { type: QueryTypes.DELETE, url: `company/offer/${jobId}/` },
    {
      root: true,
    },
  )
}

export const getAllJobs = async (
  { dispatch },
  { stage, assignedTo, city, workflow, page, pageSize, ordering, search },
) => {
  // Check hideSuccessSnack
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/offers/`,
      params: {
        stage,
        assigned_to: assignedTo,
        city,
        workflow__title: workflow,
        page,
        page_size: pageSize,
        ordering,
        search,
      },
    },
    {
      root: true,
    },
  )

  return { data, success }
}

export const getJobsOverview = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/offers/overview/`,
    },
    {
      root: true,
    },
  )

  return data
}

export const getJobsFilters = async ({ dispatch }, { stage, assignedTo, city, workflow }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/offer/filters-choices/`,
      params: {
        stage,
        assigned_to: assignedTo,
        city,
        workflow__title: workflow,
      },
    },
    {
      root: true,
    },
  )

  return data
}

export const getMyJobs = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    { type: QueryTypes.GET, url: 'company/offers/my/' },
    {
      root: true,
    },
  )

  return data
}

export const getJobChoices = async ({ dispatch }, { stage, candidate }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: 'company/offers/choices/',
      params: {
        candidate,
        stage,
      },
    },
    {
      root: true,
    },
  )

  return data
}

export const getJobDetail = async ({ dispatch, commit }, jobId) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    { type: QueryTypes.GET, url: `company/offer/${jobId}/` },
    {
      root: true,
    },
  )
  commit('SET_JOB_DATA', subjectAbility(PRIVATE.ATS.JOB, data))

  return data
}

export const getJobCharts = async ({ dispatch }, jobId) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/offer/${jobId}/charts/`,
    },
    {
      root: true,
    },
  )

  return data
}

const getJobCandidates = async ({ dispatch }, { jobId, stage, page, pageSize, ordering, search }) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/offer/${jobId}/candidates/`,
      params: {
        stage,
        page,
        page_size: pageSize,
        ordering,
        search,
      },
    },
    {
      root: true,
    },
  )

  return { data, success }
}

const getJobCandidatesOverview = async ({ dispatch }, jobId) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/offer/${jobId}/candidates/overview/`,
    },
    {
      root: true,
    },
  )

  return data
}

const getJobCandidatesFilters = async ({ dispatch }, { jobId }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/offer/${jobId}/candidates/filter-choices/`,
    },
    {
      root: true,
    },
  )

  return data
}

const getJobCandidateDetails = async ({ dispatch, commit }, { jobId, candidateId }) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/offer/${jobId}/candidate/`,
      params: {
        candidate_id: candidateId,
      },
    },
    {
      root: true,
    },
  )
  commit('SET_JOB_CANDIDATE_DATA', subjectAbility(PRIVATE.ATS.JOB_CANDIDATE, data))

  return { data, success }
}

export const updateCandidateInOffer = async ({ dispatch }, { candidateDetailId, payload }) => {
  const { success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/offer/candidate/${candidateDetailId}/update/`,
      payload,
    },
    {
      root: true,
    },
  )

  return { success }
}

export const uploadFileCandidateInOffer = async ({ dispatch }, { candidateDetailId, payload }) => {
  const { success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `company/offer/candidate/${candidateDetailId}/upload/`,
      payload,
    },
    {
      root: true,
    },
  )

  return { success }
}

export const updateFileCandidateInOffer = async ({ dispatch }, file) => {
  const { id: fileId, ...payload } = file
  const { success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/offer/candidate/file/${fileId}/update/`,
      payload,
    },
    {
      root: true,
    },
  )

  return { success }
}

const getCandidatesAnswer = async ({ dispatch }, { jobId, candidateId }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/offer/${jobId}/candidate/answers/`,
      params: {
        candidate_id: candidateId,
      },
    },
    {
      root: true,
    },
  )

  return data
}

const rejectCandidates = async (
  { dispatch },
  { jobId, candidatesOfferDetail, bodyHtml, subject, lang, cc, sendCopyToRecruiter, rejectReason, sendEmail },
) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/offer/${jobId}/candidates/action/`,
      payload: {
        ids: [...candidatesOfferDetail],
        stage: 'rejected',
        bodyHtml,
        subject,
        lang,
        cc,
        sendCopyToRecruiter,
        rejectReason,
        sendEmail,
      },
    },
    {
      root: true,
    },
  )

  return data
}

const rejectAllCandidates = async (
  { dispatch },
  { jobId, bodyHtml, subject, lang, cc, sendCopyToRecruiter, rejectReason, sendEmail },
) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/offer/${jobId}/candidates/action/reject-all/`,
      payload: {
        bodyHtml,
        subject,
        lang,
        cc,
        sendCopyToRecruiter,
        rejectReason,
        sendEmail,
      },
    },
    {
      root: true,
    },
  )

  return { data, success }
}

const hireCandidates = async (
  { dispatch },
  { jobId, candidatesOfferDetail, bodyHtml, subject, lang, cc, sendCopyToRecruiter, workingStartDay, sendEmail },
) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/offer/${jobId}/candidates/action/`,
      payload: {
        ids: [...candidatesOfferDetail],
        stage: 'hired',
        bodyHtml,
        subject,
        lang,
        cc,
        sendCopyToRecruiter,
        workingStartDay,
        sendEmail,
      },
    },
    {
      root: true,
    },
  )

  return data
}

const moveCandidatesToNextStep = async (
  { dispatch },
  { jobId, candidatesOfferDetail, bodyHtml, subject, lang, cc, sendCopyToRecruiter, sendEmail },
) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/offer/${jobId}/candidates/action/`,
      payload: {
        ids: [...candidatesOfferDetail],
        stage: 'in_progress',
        bodyHtml,
        subject,
        lang,
        cc,
        sendCopyToRecruiter,
        sendEmail,
      },
    },
    {
      root: true,
    },
  )

  return data
}

const changeJobStage = async ({ dispatch }, { jobId, stage }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/offer/${jobId}/update-stage/`,
      payload: {
        stage,
      },
    },
    {
      root: true,
    },
  )

  return data
}

const applyToJob = async ({ dispatch }, { jobId, payload }) => {
  await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `company/offer/${jobId}/apply/`,
      payload,
    },
    {
      root: true,
    },
  )
}

const manualApplyToJob = async ({ dispatch }, { jobId, payload }) => {
  await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `company/offer/${jobId}/manual-apply/`,
      payload,
    },
    {
      root: true,
    },
  )
}

const getManualApplyData = async ({ dispatch }, jobId) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/offer/${jobId}/manual-apply/`,
    },
    {
      root: true,
    },
  )

  return { data, success }
}

export const createWorkflowTemplate = async ({ dispatch }, payload) => {
  await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `company/workflow-template/create/`,
      payload,
    },
    {
      root: true,
    },
  )
}

export const getWorkflowTemplates = async ({ commit, dispatch }, { search, page, pageSize, ordering }) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/workflow-template/`,
      params: {
        search,
        page,
        page_size: pageSize,
        ordering,
      },
    },
    {
      root: true,
    },
  )
  commit('SET_WORKFLOW', data)

  return { data, success }
}

export const getWorkflowTemplateDetails = async ({ dispatch, commit }, workflowId) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/workflow-template/${workflowId}/`,
    },
    {
      root: true,
    },
  )

  if (success) commit('SET_WORKFLOW_TEMPLATE_DATA', data)

  return data
}

export const updateWorkflowTemplate = async ({ dispatch, commit }, { workflowId, payload }) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PUT,
      url: `company/workflow-template/${workflowId}/`,
      payload,
    },
    {
      root: true,
    },
  )

  if (success) commit('SET_WORKFLOW_TEMPLATE_DATA', data)

  return { data, success }
}

export const getAllWorkflowTemplatesDetails = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/workflow-template/all-details/`,
    },
    {
      root: true,
    },
  )

  return data
}

export const deleteWorkflowTemplate = async ({ dispatch }, workflowId) => {
  await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.DELETE,
      url: `company/workflow-template/${workflowId}/`,
    },
    {
      root: true,
    },
  )
}

export const getApplyFormSourceCategories = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/settings/jobs/apply-form-source-categories/`,
    },
    {
      root: true,
    },
  )

  return data
}

export const updateApplyFormSourceCategories = async ({ dispatch }, payload) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/settings/jobs/apply-form-source-categories/`,
      payload,
    },
    {
      root: true,
    },
  )

  return data
}

export const getApplyFormSourcesChoices = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/settings/jobs/apply-form-sources/choices/`,
    },
    {
      root: true,
    },
  )

  return data
}

export const getApplyFormSourceLink = async ({ dispatch }, { jobId, source }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/offer/${jobId}/apply/link/`,
      params: { source },
    },
    {
      root: true,
    },
  )

  return data.applyFormUrl
}

export const getRejectReasonsCategories = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/settings/jobs/reject-reason-categories/`,
    },
    {
      root: true,
    },
  )

  return data
}

export const updateRejectReasonsCategories = async ({ dispatch }, payload) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/settings/jobs/reject-reason-categories/`,
      payload,
    },
    {
      root: true,
    },
  )

  return data
}

export const getRejectReasonsChoices = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/settings/jobs/reject-reasons/choices/`,
    },
    {
      root: true,
    },
  )

  return data
}

export const getEmailTemplateActionsChoices = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/settings/email-templates/actions/choices/`,
    },
    {
      root: true,
    },
  )

  return data
}

export const getEmailTemplates = async ({ dispatch }, { action, lang, page, pageSize, ordering, search }) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/settings/email-templates/`,
      params: {
        action,
        lang,
        page,
        page_size: pageSize,
        ordering,
        search,
      },
    },
    {
      root: true,
    },
  )

  return { data, success }
}

export const getEmailTemplatesFilters = async ({ dispatch }, { action, lang, search }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/settings/email-templates/filter-choices/`,
      params: {
        action,
        lang,
        search,
      },
    },
    {
      root: true,
    },
  )

  return data
}

export const createEmailTemplate = async ({ dispatch }, { payload }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `company/settings/email-templates/create/`,
      payload,
    },
    {
      root: true,
    },
  )

  return data
}

export const getEmailTemplate = async ({ dispatch, commit }, id) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/settings/email-template/${id}/`,
    },
    {
      root: true,
    },
  )

  commit('SET_EMAIL_TEMPLATE_DATA', data)

  return data
}

export const updateEmailTemplate = async ({ dispatch, commit }, { id, payload }) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/settings/email-template/${id}/`,
      payload,
    },
    {
      root: true,
    },
  )

  commit('SET_EMAIL_TEMPLATE_DATA', data)

  return { success }
}

export const deleteEmailTemplate = async ({ dispatch }, id) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.DELETE,
      url: `company/settings/email-template/${id}/`,
    },
    {
      root: true,
    },
  )

  return data
}

export const getEmailTemplatesChoices = async ({ dispatch }, { actionType }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/settings/email-templates/choices/`,
      params: {
        action: actionType,
      },
    },
    {
      root: true,
    },
  )

  return data
}

export const sendTestEmail = async ({ dispatch }, payload) => {
  const { success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `company/settings/email-templates/send-test-email/`,
      payload,
    },
    {
      root: true,
    },
  )

  // TODO: return true / false
  return success
}

export const getCandidateInternalNotes = async ({ dispatch }, { candidateDetailsId }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/offer/${candidateDetailsId}/internal-notes/`,
    },
    {
      root: true,
    },
  )

  return data
}

export const createCandidateInternalNotes = async ({ dispatch }, { candidateDetailsId, payload }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `company/offer/${candidateDetailsId}/internal-notes/create/`,
      payload,
    },
    {
      root: true,
    },
  )

  return data
}

export const duplicateEmailTemplate = async ({ dispatch }, emailTemplateId) => {
  await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `company/settings/email-templates/duplicate/`,
      payload: { clonedObjectId: emailTemplateId },
    },
    {
      root: true,
    },
  )
}

export const duplicateWorkflowTemplate = async ({ dispatch }, workflowTemplateId) => {
  await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `company/workflow-template/duplicate/`,
      payload: { clonedObjectId: workflowTemplateId },
    },
    {
      root: true,
    },
  )
}

export const duplicateJob = async ({ dispatch }, jobId) => {
  await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `company/offer/duplicate/`,
      payload: { clonedObjectId: jobId },
    },
    {
      root: true,
    },
  )
}

// eslint-disable-next-line camelcase
export const exportJobs = async ({ commit }, { fields, column_names, stage, jobId }) => {
  try {
    const response = await axiosIns.get(`company/offers/export/`, {
      params: { fields, column_names, stage, jobId },
    })

    return {
      success: true,
      data: response.data,
      fileName: response.headers['content-disposition'].split('filename=')[1].split(';')[0],
    }
  } catch (e) {
    commit('app/SET_ERROR_SNACK', i18n.t('somethingWentWrong'), {
      root: true,
    })
    console.error(e)

    return { success: false }
  }
}

export const deleteCandidateInternalNote = async ({ dispatch }, internalNoteId) => {
  const { success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.DELETE,
      url: `company/offer/candidate/internal-note/${internalNoteId}/`,
    },
    { root: true },
  )

  return { success }
}

export default {
  getCurrentJobs,
  getAllJobs,
  getJobsOverview,
  getMyJobs,
  getJobChoices,
  getJobsFilters,
  getJobDetail,
  getJobCharts,
  createJob,
  updatedJob,
  updatedJobPart,
  deleteJob,
  createWorkflowTemplate,
  getWorkflowTemplates,
  getWorkflowTemplateDetails,
  updateWorkflowTemplate,
  getAllWorkflowTemplatesDetails,
  deleteWorkflowTemplate,
  getApplyFormSourceCategories,
  updateApplyFormSourceCategories,
  getApplyFormSourcesChoices,
  getApplyFormSourceLink,
  getCandidatesAnswer,
  getJobCandidates,
  getJobCandidatesOverview,
  getJobCandidatesFilters,
  getJobCandidateDetails,
  updateCandidateInOffer,
  uploadFileCandidateInOffer,
  updateFileCandidateInOffer,
  rejectCandidates,
  rejectAllCandidates,
  hireCandidates,
  moveCandidatesToNextStep,
  changeJobStage,
  applyToJob,
  manualApplyToJob,
  getManualApplyData,
  getRejectReasonsCategories,
  updateRejectReasonsCategories,
  getRejectReasonsChoices,
  getEmailTemplateActionsChoices,
  getEmailTemplates,
  getEmailTemplatesFilters,
  createEmailTemplate,
  getEmailTemplate,
  updateEmailTemplate,
  deleteEmailTemplate,
  getEmailTemplatesChoices,
  sendTestEmail,
  getCandidateInternalNotes,
  createCandidateInternalNotes,
  duplicateEmailTemplate,
  duplicateWorkflowTemplate,
  duplicateJob,
  exportJobs,

  deleteCandidateInternalNote,
}
