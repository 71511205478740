import { AbilityBuilder, createMongoAbility } from '@casl/ability'
import { PRIVATE, PUBLIC, ACTIONS, MANAGE, ALL } from './const'

export const initialAbility = [
  {
    action: 'read',
    subject: 'Public',
  },
]

const defineRulesBusinessSettings = (businessSettings, can, cannot, access) => {
  if (businessSettings.lightStaffActive) {
    can(MANAGE, PRIVATE.STAFF.EMPLOYEES)
    can(MANAGE, PRIVATE.SETTINGS.EMPLOYEES)
    if (access === 'admin') {
      can(ACTIONS.DELETE, PRIVATE.STAFF.EMPLOYEES)
      can(ACTIONS.DELETE, PRIVATE.STAFF.EMPLOYEES_FILES)
    } else {
      cannot(ACTIONS.DELETE, PRIVATE.STAFF.EMPLOYEES)
      cannot(ACTIONS.DELETE, PRIVATE.STAFF.EMPLOYEES_FILES)
    }
  } else {
    cannot(MANAGE, PRIVATE.STAFF.EMPLOYEES)
    cannot(MANAGE, PRIVATE.SETTINGS.EMPLOYEES)
  }

  if (businessSettings.lightStaffHouseActive) {
    can(MANAGE, PRIVATE.STAFF.HOUSES)
    can(MANAGE, PRIVATE.SETTINGS.HOUSES)
    if (access === 'admin') {
      can(ACTIONS.DELETE, PRIVATE.STAFF.HOUSES_FILES)
    } else {
      cannot(ACTIONS.DELETE, PRIVATE.STAFF.HOUSES_FILES)
    }
  } else {
    cannot(MANAGE, PRIVATE.STAFF.HOUSES)
    cannot(MANAGE, PRIVATE.SETTINGS.HOUSES)
  }

  if (businessSettings.lightStaffClientActive) {
    can(MANAGE, PRIVATE.STAFF.CLIENTS)
    can(MANAGE, PRIVATE.SETTINGS.CLIENTS)
    can(MANAGE, PRIVATE.STAFF.PROJECTS)
    can(MANAGE, PRIVATE.SETTINGS.PROJECTS)
    if (access === 'admin') {
      can(ACTIONS.DELETE, PRIVATE.STAFF.CLIENTS_FILES)
      can(ACTIONS.DELETE, PRIVATE.STAFF.PROJECTS_FILES)
    } else {
      cannot(ACTIONS.DELETE, PRIVATE.STAFF.CLIENTS_FILES)
      cannot(ACTIONS.DELETE, PRIVATE.STAFF.PROJECTS_FILES)
    }
  } else {
    cannot(MANAGE, PRIVATE.STAFF.CLIENTS)
    cannot(MANAGE, PRIVATE.SETTINGS.CLIENTS)
    cannot(MANAGE, PRIVATE.STAFF.PROJECTS)
    cannot(MANAGE, PRIVATE.SETTINGS.PROJECTS)
  }

  if (Object.values(businessSettings).some((featureActive) => featureActive === true)) {
    can(ACTIONS.READ, PRIVATE.STAFF.MENU_HEADER)
  } else {
    cannot(ACTIONS.READ, PRIVATE.STAFF.MENU_HEADER)
  }

  if (businessSettings.package === 'G') {
    can(MANAGE, PRIVATE.GLOW_FEATURES)
    cannot(MANAGE, PRIVATE.SHINE_FEATURES)
    cannot(MANAGE, PRIVATE.LUMINATE_FEATURES)
  } else if (businessSettings.package === 'S') {
    can(MANAGE, PRIVATE.GLOW_FEATURES)
    can(MANAGE, PRIVATE.SHINE_FEATURES)
    cannot(MANAGE, PRIVATE.LUMINATE_FEATURES)
  } else if (businessSettings.package === 'L') {
    can(MANAGE, PRIVATE.GLOW_FEATURES)
    can(MANAGE, PRIVATE.SHINE_FEATURES)
    can(MANAGE, PRIVATE.LUMINATE_FEATURES)
  }
}

export default function defineRules(access, recruiterId, businessSettings) {
  const { can, cannot, build } = new AbilityBuilder(createMongoAbility)

  switch (access) {
    case 'admin':
      can(MANAGE, ALL)
      cannot(ACTIONS.DELETE, PRIVATE.ATS.JOB, { stage: { $in: ['ongoing', 'finished', 'archived'] } })
      cannot(ACTIONS.JOBS.MANAGE_JOB, PRIVATE.ATS.JOB, { canManageJob: false })
      cannot(ACTIONS.JOBS.MANAGE_JOB_STAGE, PRIVATE.ATS.JOB, { canChangeJobStage: false })
      cannot(ACTIONS.JOBS.MANAGE_CANDIDATES, PRIVATE.ATS.JOB, { canManageCandidates: false })
      defineRulesBusinessSettings(businessSettings, can, cannot, access)
      break
    case 'recruiter':
      can(MANAGE, ALL)

      cannot(ACTIONS.DELETE, PRIVATE.ATS.JOB, { stage: { $in: ['ongoing', 'finished', 'archived'] } })
      cannot(ACTIONS.DELETE, PRIVATE.ATS.JOB, { canManageJob: false })

      cannot(ACTIONS.JOBS.MANAGE_JOB, PRIVATE.ATS.JOB, { canManageJob: false })
      cannot(ACTIONS.JOBS.MANAGE_JOB_STAGE, PRIVATE.ATS.JOB, { canChangeJobStage: false })

      cannot(ACTIONS.JOBS.MANAGE_CANDIDATES, PRIVATE.ATS.JOB, { canManageCandidates: false })

      cannot(ACTIONS.READ, PRIVATE.ATS.JOB_CANDIDATE, { canViewCandidate: false })
      cannot(ACTIONS.UPDATE, PRIVATE.ATS.JOB_CANDIDATE, { canManageCandidate: false })
      cannot(ACTIONS.DELETE, PRIVATE.ATS.JOB_CANDIDATE, { canManageCandidate: false })

      cannot(MANAGE, PRIVATE.SETTINGS.USER_MANAGEMENT)
      cannot(MANAGE, PRIVATE.SETTINGS.COMPANY)
      cannot(ACTIONS.UPDATE, PRIVATE.DASHBOARD)
      cannot(ACTIONS.DELETE, PRIVATE.INTERNAL_NOTE)
      defineRulesBusinessSettings(businessSettings, can, cannot, access)
      break
    case 'expert':
      can(ACTIONS.READ, PUBLIC)

      can(ACTIONS.READ, PRIVATE.ATS.BOARD)
      can(ACTIONS.READ, PRIVATE.SETTINGS.ACCOUNT)
      can(ACTIONS.READ, PRIVATE.ATS.JOBS)

      can(ACTIONS.READ, PRIVATE.ATS.JOB, { 'sharedWith.id': recruiterId })

      can(ACTIONS.DELETE, PRIVATE.ATS.JOB, { stage: { $in: ['draft'] }, canManageJob: true })

      can(ACTIONS.JOBS.MANAGE_JOB, PRIVATE.ATS.JOB, { canManageJob: true })
      can(ACTIONS.JOBS.MANAGE_JOB_STAGE, PRIVATE.ATS.JOB, { canChangeJobStage: true })

      can(ACTIONS.JOBS.MANAGE_CANDIDATES, PRIVATE.ATS.JOB, { 'sharedWith.id': recruiterId })

      can(ACTIONS.READ, PRIVATE.ATS.JOB_CANDIDATE, { canViewCandidate: true })
      can(ACTIONS.DELETE, PRIVATE.ATS.JOB_CANDIDATE, { canManageCandidate: true })
      can(ACTIONS.UPDATE, PRIVATE.ATS.JOB_CANDIDATE, { canManageCandidate: true })
      break
    default:
      can(ACTIONS.READ, PUBLIC)
  }

  return build()
}

export const _ = undefined
