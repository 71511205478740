// import appAndPages from './app-and-pages'
// import chartsAndMaps from './charts-and-maps'
import dashboard from './dashboard'
import reminders from './reminders'

// import formsAndTables from './forms-and-tables'
// import others from './others'
// import uiElements from './ui-elements'
import ats from './ats'
import staff from './staff'
import templates from './templates'
import settings from './settings'

// Array of sections
export default [...dashboard, ...reminders, ...ats, ...staff, ...templates, ...settings]
