import { PRIVATE } from '@/plugins/acl/const'

const reminders = [
  {
    path: '/reminders/',
    name: 'reminders',
    component: () => import('@/views/reminders/RemindersList.vue'),
    meta: {
      layout: 'content',
      resource: PRIVATE.REMINDERS,
    },
  },
]

export default reminders
