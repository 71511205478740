import { axiosIns } from '@/plugins/axios'
import { i18n } from '@/plugins/i18n'

export const QueryTypes = {
  GET: 'get',
  POST: 'post',
  PATCH: 'patch',
  PUT: 'put',
  DELETE: 'delete',
}

export const axiosQueryBuilder = async ({ commit }, { type, url, payload, params }) => {
  const query = {
    get: (queryUrl, queryParams) => axiosIns.get(queryUrl, { params: queryParams }),
    post: (queryUrl, queryPayload) => axiosIns.post(queryUrl, queryPayload),
    patch: (queryUrl, queryPayload) => axiosIns.patch(queryUrl, queryPayload),
    put: (queryUrl, queryPayload) => axiosIns.put(queryUrl, queryPayload),
    delete: (queryUrl) => axiosIns.delete(queryUrl),
  }
  try {
    const { data } =
      type === QueryTypes.DELETE
        ? await query[type](url)
        : await query[type](url, type === QueryTypes.GET ? params : payload)

    if (type !== QueryTypes.GET)
      commit('app/SET_SUCCESS_SNACK', i18n.t('done'), {
        root: true,
      })

    return { data, success: true }
  } catch (e) {
    if (e.response?.data) {
      if (Array.isArray(e.response.data)) {
        commit('app/SET_ERROR_SNACK', e.response.data.join('\n'), { root: true })
        console.error(e)
      }

      return { data: e.response.data, success: false }
    }
    commit('app/SET_ERROR_SNACK', i18n.t('somethingWentWrong'), {
      root: true,
    })
    console.error(e)

    return { data: e.response, success: false }
  }
}

export default {
  axiosQueryBuilder,
}
