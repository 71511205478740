import { mdiHomeOutline, mdiBriefcaseOutline, mdiDomain } from '@mdi/js'
import { PRIVATE } from '@/plugins/acl/const'

export default [
  {
    subheader: 'staff',
    resource: PRIVATE.STAFF.MENU_HEADER,
    action: 'read',
  },
  {
    title: 'employees',
    icon: 'M16 9C16 14.33 8 14.33 8 9H10C10 11.67 14 11.67 14 9M20 18V21H4V18C4 15.33 9.33 14 12 14C14.67 14 20 15.33 20 18M18.1 18C18.1 17.36 14.97 15.9 12 15.9C9.03 15.9 5.9 17.36 5.9 18V19.1H18.1M12.5 2C12.78 2 13 2.22 13 2.5V5.5H14V3C15.45 3.67 16.34 5.16 16.25 6.75C16.25 6.75 16.95 6.89 17 8H7C7 6.89 7.75 6.75 7.75 6.75C7.66 5.16 8.55 3.67 10 3V5.5H11V2.5C11 2.22 11.22 2 11.5 2',
    to: 'apps-employee-list',
    resource: PRIVATE.STAFF.EMPLOYEES,
    action: 'read',
  },
  {
    title: 'houses',
    icon: mdiHomeOutline,
    to: 'apps-houses-list',
    resource: PRIVATE.STAFF.HOUSES,
    action: 'read',
  },
  {
    title: 'clients',
    icon: mdiDomain,
    to: 'apps-client-list',
    resource: PRIVATE.STAFF.CLIENTS,
    action: 'read',
  },
  {
    title: 'projects',
    icon: mdiBriefcaseOutline,
    to: 'apps-project-list',
    resource: PRIVATE.STAFF.PROJECTS,
    action: 'read',
  },
]
