import { mutationFactory } from 'vuex-factories'

export default {
  RESET_RECRUITER(state) {
    state.recruiter = {
      user: {
        firstName: '',
        lastName: '',
      },
    }
  },
  ...mutationFactory({
    SET_RECRUITER: [{ key: 'recruiter' }],
  }),
}
