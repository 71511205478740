import { mutationFactory } from 'vuex-factories'
import actions from './actions'

export default {
  namespaced: true,
  state: {
    documentTemplateData: {},
  },
  actions,
  mutations: {
    ...mutationFactory({
      SET_DOCUMENT_TEMPLATE_DATA: [{ key: 'documentTemplateData' }],
    }),
  },
}
