import { mutationFactory } from 'vuex-factories'
import actions from './actions'

export default {
  namespaced: true,
  state: {
    settings: {},
    recruiters: [],
    businessSettings: {},
    company: {
      name: '',
      street: '',
      zipCode: '',
      houseNumber: '',
      flatNumber: '',
      city: '',
      state: '',
      country: '',
      taxId: '',
      companyRegistrationName: '',
      companyMail: '',
      gdprText: '',
    },
  },
  getters: {
    recruitersToSelect: (state) => {
      return state.recruiters.map((recruiter) => {
        return {
          text: `${recruiter.name} ${recruiter.surname}`,
          value: recruiter.id,
        }
      })
    },
    getOfferRecruiterData: (state) => (assignedRecruiterId) => {
      return state.recruiters.find((r) => r.id === assignedRecruiterId)
    },
  },
  actions,
  mutations: {
    ...mutationFactory({
      SET_RECRUITERS: [{ key: 'recruiters' }],
      SET_COMPANY_SETTINGS: [{ key: 'company' }],
      SET_BUSINESS_SETTINGS: [{ key: 'businessSettings' }],
    }),
  },
}
