import { mdiViewDashboardOutline, mdiAccountSearchOutline, mdiAccountGroupOutline } from '@mdi/js'
import { PRIVATE } from '@/plugins/acl/const'

export default [
  {
    subheader: 'ATS',
    resource: PRIVATE.ATS.JOBS,
    action: 'read',
  },
  {
    title: 'jobs',
    icon: mdiAccountSearchOutline,
    to: 'apps-ats-jobs-list',
    resource: PRIVATE.ATS.JOBS,
    action: 'read',
  },
  {
    title: 'talentPool',
    icon: mdiAccountGroupOutline,
    to: 'apps-ats-talent-pool-list',
    resource: PRIVATE.ATS.TALENT_POOL,
    action: 'read',
  },
]
