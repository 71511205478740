import { axiosIns } from '@axios'
import { QueryTypes } from '@/store/core/actions'
import { i18n } from '@/plugins/i18n'

export const getDocumentTemplateActionsChoices = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/templates/documents/actions/choices/`,
    },
    {
      root: true,
    },
  )

  return data
}

export const getDocumentTemplates = async ({ dispatch }, { module, page, pageSize, ordering, search }) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/templates/documents/`,
      params: {
        module,
        page,
        page_size: pageSize,
        ordering,
        search,
      },
    },
    {
      root: true,
    },
  )

  return { data, success }
}

export const getDocumentTemplatesFilters = async ({ dispatch }, { module, search }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/templates/documents/filter-choices/`,
      params: {
        module,
        search,
      },
    },
    {
      root: true,
    },
  )

  return data
}

export const createDocumentTemplate = async ({ dispatch }, { payload }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `company/templates/documents/create/`,
      payload,
    },
    {
      root: true,
    },
  )

  return data
}

export const getDocumentTemplate = async ({ dispatch, commit }, id) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/templates/document/${id}/`,
    },
    {
      root: true,
    },
  )

  commit('SET_DOCUMENT_TEMPLATE_DATA', data)

  return data
}

export const updateDocumentTemplate = async ({ dispatch, commit }, { id, payload }) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/templates/document/${id}/`,
      payload,
    },
    {
      root: true,
    },
  )

  commit('SET_DOCUMENT_TEMPLATE_DATA', data)

  return { success }
}

export const deleteDocumentTemplate = async ({ dispatch }, id) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.DELETE,
      url: `company/templates/document/${id}/`,
    },
    {
      root: true,
    },
  )

  return data
}

export const getDocumentTemplatesChoices = async ({ dispatch }, { module }) => {
  const { success, data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/templates/documents/choices/`,
      params: {
        module,
      },
    },
    {
      root: true,
    },
  )
  if (!success) return []

  return data
}

export const generateDocument = async ({ commit }, { obj, contentHtml, module, fileName, context }) => {
  try {
    const response = await axiosIns.post(`company/templates/documents/generate-document/`, {
      obj,
      contentHtml,
      module,
      fileName,
      context,
    })

    return {
      success: true,
      data: response.data,
      fileName: response.headers['content-disposition'].split('filename=')[1].split(';')[0],
    }
  } catch (e) {
    commit('app/SET_ERROR_SNACK', i18n.t('somethingWentWrong'), {
      root: true,
    })
    console.error(e)

    return { success: false }
  }
}

export const duplicateDocumentTemplate = async ({ dispatch }, documentTemplateId) => {
  await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `company/templates/documents/duplicate/`,
      payload: { clonedObjectId: documentTemplateId },
    },
    {
      root: true,
    },
  )
}

export default {
  getDocumentTemplateActionsChoices,
  getDocumentTemplates,
  getDocumentTemplatesFilters,
  createDocumentTemplate,
  getDocumentTemplate,
  updateDocumentTemplate,
  deleteDocumentTemplate,
  getDocumentTemplatesChoices,
  generateDocument,
  duplicateDocumentTemplate,
}
