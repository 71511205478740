import { axiosIns } from '@axios'
import { subject } from '@casl/ability'
import _ from 'lodash'
import { QueryTypes } from '@/store/core/actions'
import { i18n } from '@/plugins/i18n'
import { PRIVATE } from '@/plugins/acl/const'

export const getEmployees = async ({ dispatch }, { tags, status, languages, search, page, pageSize, ordering }) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/employees/`,
      params: {
        candidate__tags: tags,
        status,
        languages,
        search,
        page,
        page_size: pageSize,
        ordering,
      },
    },
    { root: true },
  )

  return { data, success }
}

export const getEmployeeChoices = async ({ dispatch }, { tags, status, languages, search }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/employees/filter-choices/`,
      params: {
        candidate__tags: tags,
        status,
        languages,
        search,
      },
    },
    { root: true },
  )

  return data
}

export const getEmployeesOverview = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/employees/overview/`,
    },
    { root: true },
  )

  return data
}

export const getEmployeeDetails = async ({ dispatch, commit }, employeeId) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/employee/${employeeId}/`,
    },
    { root: true },
  )
  if (success) commit('SET_EMPLOYEE_DATA', subject(PRIVATE.STAFF.EMPLOYEES, data))

  return { data, success }
}

export const deleteEmployee = async ({ dispatch }, employeeId) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.DELETE,
      url: `company/staff/employee/${employeeId}/`,
    },
    { root: true },
  )

  return { data, success }
}

export const getLanguagesOptions = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/core/options/languages/`,
    },
    { root: true },
  )

  return data
}

export const getEmployeeFileTypes = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/employee/file-types/`,
    },
    { root: true },
  )

  return data
}

export const updateEmployee = async ({ dispatch, commit }, { id, payload }) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/staff/employee/${id}/`,
      payload,
    },
    { root: true },
  )

  if (success) commit('SET_EMPLOYEE_DATA', subject(PRIVATE.STAFF.EMPLOYEES, data))

  return { data, success }
}

export const uploadEmployeeFiles = async ({ dispatch }, { employeeId, payload }) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `company/staff/employee/${employeeId}/upload-files/`,
      payload,
    },
    { root: true },
  )

  return { success, data }
}

export const createEmployee = async ({ dispatch }, payload) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `company/staff/employees/create/`,
      payload,
    },
    { root: true },
  )

  return data
}

export const getHouses = async ({ dispatch }, { status, tags, hideFullHouses, search, page, pageSize, ordering }) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/houses/`,
      params: {
        status,
        tags,
        hide_full_houses: hideFullHouses,
        search,
        page,
        page_size: pageSize,
        ordering,
      },
    },
    { root: true },
  )

  return { data, success }
}

export const getHousesOverview = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/houses/overview/`,
    },
    { root: true },
  )

  return data
}

export const getHouseDetails = async ({ dispatch, commit }, houseId) => {
  const { success, data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/house/${houseId}/`,
    },
    { root: true },
  )
  if (success) commit('SET_HOUSE_DATA', data)

  return data
}

export const getHousesFileTypes = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/houses/file-types/`,
    },
    { root: true },
  )

  return data
}

export const uploadHouseFiles = async ({ dispatch }, { houseId, payload }) => {
  const { success, data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `company/staff/house/${houseId}/upload-files/`,
      payload,
    },
    { root: true },
  )

  return { success, data }
}

export const getAllEmployeesChoices = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/employees/choices/`,
    },
    { root: true },
  )

  return data
}

export const updateHouseDetails = async ({ dispatch, commit }, houseData) => {
  const { id, ...payload } = houseData
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/staff/house/${id}/`,
      payload,
    },
    { root: true },
  )
  if (success) commit('SET_HOUSE_DATA', data)

  return { data, success }
}

export const deleteHouse = async ({ dispatch, commit }, houseId) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.DELETE,
      url: `company/staff/house/${houseId}/`,
    },
    { root: true },
  )
  if (success) commit('SET_HOUSE_DATA', {})

  return data
}

export const createHouse = async ({ dispatch }, payload) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `company/staff/houses/create/`,
      payload,
    },
    { root: true },
  )

  return data
}

export const getHouseStatusChoices = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/houses/status/choices/`,
    },
    { root: true },
  )

  return data
}

export const getHouseStatuses = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/houses/statuses/`,
    },
    { root: true },
  )

  return data
}

export const updateHouseStatuses = async ({ dispatch }, payload) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/staff/houses/statuses/`,
      payload,
    },
    { root: true },
  )

  return data
}

// TODO: Remove!
export const getAvailableOccupantsByRoomChoices = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/houses/available-occupants/choices/`,
    },
    { root: true },
  )

  return data
}

export const getHousesFilterChoices = async ({ dispatch }, { status, tags, hideFullHouses, search }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/houses/filter-choices/`,
      params: {
        status,
        tags,
        hide_full_houses: hideFullHouses,
        search,
      },
    },
    { root: true },
  )

  return data
}

export const getEmployeeStatusChoices = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/employees/status/choices/`,
    },
    { root: true },
  )

  return data
}

export const getEmployeeStatuses = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/employees/statuses/`,
    },
    { root: true },
  )

  return data
}

export const updateEmployeeStatuses = async ({ dispatch }, payload) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/staff/employees/statuses/`,
      payload,
    },
    { root: true },
  )

  return data
}

export const getProjects = async ({ dispatch }, { status, client, tags, search, page, pageSize, ordering }) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/projects/`,
      params: {
        status,
        client,
        tags,
        search,
        page,
        page_size: pageSize,
        ordering,
      },
    },
    { root: true },
  )

  return { data, success }
}

export const getProjectDetails = async ({ dispatch, commit }, employeeId) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/project/${employeeId}/`,
    },
    { root: true },
  )
  commit('SET_PROJECT_DATA', data)

  return data
}

export const updateProjectDetails = async ({ dispatch, commit }, houseData) => {
  const { id, ...payload } = houseData
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/staff/project/${id}/`,
      payload,
    },
    { root: true },
  )
  commit('SET_PROJECT_DATA', data)

  return { data, success }
}

export const deleteProject = async ({ dispatch }, houseId) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.DELETE,
      url: `company/staff/project/${houseId}/`,
    },
    { root: true },
  )

  return data
}

export const getProjectStatuses = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/projects/statuses/`,
    },
    { root: true },
  )

  return data
}

export const updateProjectStatuses = async ({ dispatch }, payload) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/staff/projects/statuses/`,
      payload,
    },
    { root: true },
  )

  return data
}

export const getProjectsFilterChoices = async ({ dispatch }, { status, client, tags, search }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/projects/filter-choices/`,
      params: {
        status,
        client,
        tags,
        search,
      },
    },
    { root: true },
  )

  return data
}

export const getProjectsOverview = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/projects/overview/`,
    },
    { root: true },
  )

  return data
}

export const getProjectsFileTypes = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/projects/file-types/`,
    },
    { root: true },
  )

  return data
}

export const uploadProjectFiles = async ({ dispatch }, { projectId, payload }) => {
  const { success, data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `company/staff/project/${projectId}/upload-files/`,
      payload,
    },
    { root: true },
  )

  return { success, data }
}

export const getClients = async ({ dispatch }, { status, city, tags, search, page, pageSize, ordering }) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/clients/`,
      params: {
        status,
        city,
        tags,
        search,
        page,
        page_size: pageSize,
        ordering,
      },
    },
    { root: true },
  )

  return { data, success }
}

export const getClientsOverview = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/clients/overview/`,
    },
    { root: true },
  )

  return data
}

export const getClientsFileTypes = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/clients/file-types/`,
    },
    { root: true },
  )

  return data
}

export const uploadClientFiles = async ({ dispatch }, { clientId, payload }) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `company/staff/client/${clientId}/upload-files/`,
      payload,
    },
    { root: true },
  )

  return { data, success }
}

export const getClientsFilterChoices = async ({ dispatch }, { status, city, tags, search }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/clients/filter-choices/`,
      params: {
        status,
        city,
        tags,
        search,
      },
    },
    { root: true },
  )

  return data
}

export const getClientStatuses = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/clients/statuses/`,
    },
    { root: true },
  )

  return data
}

export const updateClientStatuses = async ({ dispatch }, payload) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/staff/clients/statuses/`,
      payload,
    },
    { root: true },
  )

  return data
}

export const getProjectStatusChoices = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/projects/status/choices/`,
    },
    { root: true },
  )

  return data
}

export const getClientDetails = async ({ dispatch, commit }, clientId) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/client/${clientId}/`,
    },
    { root: true },
  )
  if (success) commit('SET_CLIENT_DATA', data)

  return data
}

export const updateClientDetails = async ({ dispatch, commit }, clientData) => {
  const { id, ...payload } = clientData
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/staff/client/${id}/`,
      payload,
    },
    { root: true },
  )
  if (success) commit('SET_CLIENT_DATA', data)

  return { data, success }
}

export const deleteClient = async ({ dispatch }, clientId) => {
  await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.DELETE,
      url: `company/staff/client/${clientId}/`,
    },
    { root: true },
  )
}

export const getClientChoices = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/projects/clients/choices/`,
    },
    { root: true },
  )

  return data
}

export const createProject = async ({ dispatch }, payload) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `company/staff/projects/create/`,
      payload,
    },
    { root: true },
  )

  return data
}

export const getClientStatusChoices = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/clients/status/choices/`,
    },
    { root: true },
  )

  return data
}

export const createClient = async ({ dispatch }, payload) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `company/staff/clients/create/`,
      payload,
    },
    { root: true },
  )

  return data
}

export const getAvailableAssigneesChoices = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/projects/available-positions/choices/`,
    },
    { root: true },
  )

  return data
}

// eslint-disable-next-line camelcase
export const exportEmployees = async ({ commit }, { fields, column_names, stage }) => {
  try {
    const response = await axiosIns.get(`company/staff/employees/export/`, {
      params: { fields, column_names, stage },
    })

    return {
      success: true,
      data: response.data,
      fileName: response.headers['content-disposition'].split('filename=')[1].split(';')[0],
    }
  } catch (e) {
    commit('app/SET_ERROR_SNACK', i18n.t('somethingWentWrong'), {
      root: true,
    })
    console.error(e)

    return { success: false }
  }
}

// eslint-disable-next-line camelcase
export const exportHouses = async ({ commit }, { fields, column_names, stage }) => {
  try {
    const response = await axiosIns.get(`company/staff/houses/export/`, {
      params: { fields, column_names, stage },
    })

    return {
      success: true,
      data: response.data,
      fileName: response.headers['content-disposition'].split('filename=')[1].split(';')[0],
    }
  } catch (e) {
    commit('app/SET_ERROR_SNACK', i18n.t('somethingWentWrong'), {
      root: true,
    })
    console.error(e)

    return { success: false }
  }
}

// eslint-disable-next-line camelcase
export const exportProjects = async ({ commit }, { fields, column_names, stage }) => {
  try {
    const response = await axiosIns.get(`company/staff/projects/export/`, {
      params: { fields, column_names, stage },
    })

    return {
      success: true,
      data: response.data,
      fileName: response.headers['content-disposition'].split('filename=')[1].split(';')[0],
    }
  } catch (e) {
    commit('app/SET_ERROR_SNACK', i18n.t('somethingWentWrong'), {
      root: true,
    })
    console.error(e)

    return { success: false }
  }
}

// eslint-disable-next-line camelcase
export const exportClients = async ({ commit }, { fields, column_names, stage }) => {
  try {
    const response = await axiosIns.get(`company/staff/clients/export/`, {
      params: { fields, column_names, stage },
    })

    return {
      success: true,
      data: response.data,
      fileName: response.headers['content-disposition'].split('filename=')[1].split(';')[0],
    }
  } catch (e) {
    commit('app/SET_ERROR_SNACK', i18n.t('somethingWentWrong'), {
      root: true,
    })
    console.error(e)

    return { success: false }
  }
}

export const getHouseTags = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/house/tags/`,
    },
    { root: true },
  )

  return data
}

export const updateHouseTags = async ({ dispatch }, payload) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/house/tags/`,
      payload,
    },
    { root: true },
  )

  return data
}

export const getProjectTags = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/project/tags/`,
    },
    { root: true },
  )

  return data
}

export const updateProjectTags = async ({ dispatch }, payload) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/project/tags/`,
      payload,
    },
    { root: true },
  )

  return data
}

export const getClientTags = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/client/tags/`,
    },
    { root: true },
  )

  return data
}

export const updateClientTags = async ({ dispatch }, payload) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/client/tags/`,
      payload,
    },
    { root: true },
  )

  return data
}

export const getHouseCustomFields = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/house/custom-fields/`,
    },
    { root: true },
  )

  return data
}

export const updateHouseCustomFields = async ({ dispatch }, payload) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/house/custom-fields/`,
      payload,
    },
    { root: true },
  )

  return data
}

export const getClientCustomFields = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/client/custom-fields/`,
    },
    { root: true },
  )

  return data
}

export const updateClientCustomFields = async ({ dispatch }, payload) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/client/custom-fields/`,
      payload,
    },
    { root: true },
  )

  return data
}

export const getProjectCustomFields = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/project/custom-fields/`,
    },
    { root: true },
  )

  return data
}

export const updateProjectCustomFields = async ({ dispatch }, payload) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/project/custom-fields/`,
      payload,
    },
    { root: true },
  )

  return data
}

export const getProjectHourlyRatesCategories = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/project/hourly-rates-categories/`,
    },
    { root: true },
  )

  return data
}

export const updateProjectHourlyRatesCategories = async ({ dispatch }, payload) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/project/hourly-rates-categories/`,
      payload,
    },
    { root: true },
  )

  return data
}

export const deleteEmployeeFile = async ({ dispatch }, fileId) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.DELETE,
      url: `company/staff/employee/file/${fileId}/`,
    },
    { root: true },
  )

  return { data, success }
}

export const retrieveEmployeeFile = async ({ dispatch }, fileId) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/employee/file/${fileId}/`,
    },
    { root: true },
  )

  return { data, success }
}

export const updateEmployeeFile = async ({ dispatch }, file) => {
  const { id: fileId, ...payload } = file
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/staff/employee/file/${fileId}/`,
      payload,
    },
    { root: true },
  )

  return { data, success }
}

export const deleteHouseFile = async ({ dispatch }, fileId) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.DELETE,
      url: `company/staff/house/file/${fileId}/`,
    },
    { root: true },
  )

  return { data, success }
}

export const updateHouseFile = async ({ dispatch }, file) => {
  const { id: fileId, ...payload } = file
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/staff/house/file/${fileId}/`,
      payload,
    },
    { root: true },
  )

  return { data, success }
}

export const deleteProjectFile = async ({ dispatch }, fileId) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.DELETE,
      url: `company/staff/project/file/${fileId}/`,
    },
    { root: true },
  )

  return { data, success }
}

export const updateProjectFile = async ({ dispatch }, file) => {
  const { id: fileId, ...payload } = file
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/staff/project/file/${fileId}/`,
      payload,
    },
    { root: true },
  )

  return { data, success }
}

export const deleteClientFile = async ({ dispatch }, fileId) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.DELETE,
      url: `company/staff/client/file/${fileId}/`,
    },
    { root: true },
  )

  return { data, success }
}

export const updateClientFile = async ({ dispatch }, file) => {
  const { id: fileId, ...payload } = file
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/staff/client/file/${fileId}/`,
      payload,
    },
    { root: true },
  )

  return { data, success }
}

export const getPositionDetails = async ({ dispatch, commit }, positionId) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/position/${positionId}/`,
    },
    { root: true },
  )
  commit('SET_POSITION_DATA', data)

  return data
}

export const updatePositionDetails = async ({ dispatch, commit }, positionData) => {
  const { id, ...payload } = positionData
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/staff/position/${id}/`,
      payload,
    },
    { root: true },
  )
  commit('SET_POSITION_DATA', data)

  return { data, success }
}

export const deletePosition = async ({ dispatch }, positionId) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.DELETE,
      url: `company/staff/position/${positionId}/`,
    },
    { root: true },
  )

  return data
}

export const createPosition = async ({ dispatch }, payload) => {
  const { success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `company/staff/position/create/`,
      payload,
    },
    { root: true },
  )

  return success
}

export const getProjectsChoices = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: 'company/staff/projects/choices/',
    },
    { root: true },
  )

  return data
}

export const updateAssigneeDetails = async ({ dispatch }, { assigneeData }) => {
  const { id, ...payload } = assigneeData
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/staff/position/assignee/${id}/`,
      payload,
    },
    { root: true },
  )

  return { data, success }
}

export const deleteAssignee = async ({ dispatch }, { assigneeId }) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.DELETE,
      url: `company/staff/position/assignee/${assigneeId}/`,
    },
    { root: true },
  )

  return { data, success }
}

export const createAssignees = async ({ dispatch }, payload) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `company/staff/position/assignee/create/`,
      payload,
    },
    { root: true },
  )

  return { data, success }
}

// --------------------------------------------------------

export const getRoomDetails = async ({ dispatch, commit }, roomId) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/room/${roomId}/`,
    },
    { root: true },
  )
  commit('SET_ROOM_DATA', data)

  return data
}

export const updateRoomDetails = async ({ dispatch, commit }, roomData) => {
  const { id, ...payload } = roomData
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/staff/room/${id}/`,
      payload,
    },
    { root: true },
  )
  commit('SET_ROOM_DATA', data)

  return { data, success }
}

export const deleteRoom = async ({ commit, dispatch }, positionId) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.DELETE,
      url: `company/staff/room/${positionId}/`,
    },
    { root: true },
  )

  commit('SET_ROOM_DATA', {})

  return data
}

export const createRoom = async ({ dispatch }, payload) => {
  const { success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `company/staff/room/create/`,
      payload,
    },
    { root: true },
  )

  return success
}

export const getHouseChoices = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: 'company/staff/houses/choices/',
    },
    { root: true },
  )

  return data
}

export const updateOccupantDetails = async ({ dispatch }, { occupantData }) => {
  console.log(occupantData)
  const { id, ...payload } = occupantData
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/staff/room/occupant/${id}/`,
      payload,
    },
    { root: true },
  )

  return { data, success }
}

export const deleteOccupant = async ({ dispatch }, { occupantId }) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.DELETE,
      url: `company/staff/room/occupant/${occupantId}/`,
    },
    { root: true },
  )

  return { data, success }
}

export const createOccupants = async ({ dispatch }, payload) => {
  const { data, success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `company/staff/room/occupant/create/`,
      payload,
    },
    { root: true },
  )

  return { data, success }
}

export const getRoomChoices = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: 'company/staff/room/choices/',
    },
    { root: true },
  )

  return data
}

export const getRoomOccupantsChoices = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/houses/available-rooms/choices/`,
    },
    { root: true },
  )

  return data
}

export const createCustomAddress = async ({ dispatch }, customAddressData) => {
  const { success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `company/staff/employee/custom-address/create/`,
      payload: customAddressData,
    },
    { root: true },
  )

  return { success }
}

export const updateCustomAddress = async ({ dispatch }, customAddressData) => {
  const { id, ...payload } = customAddressData
  const { success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/staff/employee/custom-address/${id}/`,
      payload,
    },
    { root: true },
  )

  return { success }
}

export const deleteCustomAddress = async ({ dispatch }, customAddressId) => {
  const { success } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.DELETE,
      url: `company/staff/employee/custom-address/${customAddressId}/`,
    },
    { root: true },
  )

  return { success }
}

export const getHourlyRatesCategoriesChoices = async ({ dispatch }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/project/hourly-rates-categories/choices/`,
    },
    { root: true },
  )

  return data
}

export const getAssigneesDailyTimesheet = async ({ dispatch }, { positionId, date }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/position/${positionId}/daily-timesheets/`,
      params: {
        date,
      },
    },
    { root: true },
  )

  return data
}

export const updateAssigneesDailyTimesheet = async ({ commit }, { positionId, payload, date }) => {
  try {
    await axiosIns.patch(`company/staff/position/${positionId}/daily-timesheets/`, payload, {
      params: { date },
    })
    commit('app/SET_SUCCESS_SNACK', i18n.t('done'), {
      root: true,
    })
  } catch (e) {
    commit('app/SET_ERROR_SNACK', i18n.t('somethingWentWrong'), {
      root: true,
    })
    console.error(e)
  }
}

export const getAssigneesMonthlyTimesheet = async ({ dispatch }, { positionId, year, month }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/position/${positionId}/monthly-timesheets/`,
      params: {
        year,
        month,
      },
    },
    { root: true },
  )

  return data
}

export const updateAssigneesMonthlyTimesheet = async ({ commit }, { positionId, payload, year, month }) => {
  try {
    await axiosIns.patch(`company/staff/position/${positionId}/monthly-timesheets/`, payload, {
      params: { year, month },
    })
    commit('app/SET_SUCCESS_SNACK', i18n.t('done'), {
      root: true,
    })
  } catch (e) {
    commit('app/SET_ERROR_SNACK', i18n.t('somethingWentWrong'), {
      root: true,
    })
    console.error(e)
  }
}

export const getProjectAssigneesDailyTimesheets = async ({ dispatch }, { projectId, date }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/project/${projectId}/daily-timesheets/`,
      params: {
        date,
      },
    },
    { root: true },
  )

  return data
}

export const updateProjectAssigneesDailyTimesheets = async ({ commit }, { projectId, payload, date }) => {
  try {
    await axiosIns.patch(`company/staff/project/${projectId}/daily-timesheets/`, payload, {
      params: { date },
    })
    commit('app/SET_SUCCESS_SNACK', i18n.t('done'), {
      root: true,
    })
  } catch (e) {
    commit('app/SET_ERROR_SNACK', i18n.t('somethingWentWrong'), {
      root: true,
    })
    console.error(e)
  }
}

export const getProjectAssigneesMonthlyTimesheets = async ({ dispatch }, { projectId, year, month }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/project/${projectId}/monthly-timesheets/`,
      params: {
        year,
        month,
      },
    },
    { root: true },
  )

  return data
}

export const updateProjectAssigneesMonthlyTimesheets = async ({ commit }, { projectId, payload, year, month }) => {
  try {
    await axiosIns.patch(`company/staff/project/${projectId}/monthly-timesheets/`, payload, {
      params: { year, month },
    })
    commit('app/SET_SUCCESS_SNACK', i18n.t('done'), {
      root: true,
    })
  } catch (e) {
    commit('app/SET_ERROR_SNACK', i18n.t('somethingWentWrong'), {
      root: true,
    })
    console.error(e)
  }
}

export const getEmployeeAssigneesDailyTimesheets = async ({ dispatch }, { employeeId, date }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/employee/${employeeId}/daily-timesheets/`,
      params: {
        date,
      },
    },
    { root: true },
  )

  return data
}

export const updateEmployeeAssigneesDailyTimesheets = async ({ commit }, { employeeId, payload, date }) => {
  try {
    await axiosIns.patch(`company/staff/employee/${employeeId}/daily-timesheets/`, payload, {
      params: { date },
    })
    commit('app/SET_SUCCESS_SNACK', i18n.t('done'), {
      root: true,
    })
  } catch (e) {
    commit('app/SET_ERROR_SNACK', i18n.t('somethingWentWrong'), {
      root: true,
    })
    console.error(e)
  }
}

export const getEmployeeAssigneesMonthlyTimesheets = async ({ dispatch }, { employeeId, year, month }) => {
  const { data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/employee/${employeeId}/monthly-timesheets/`,
      params: {
        year,
        month,
      },
    },
    { root: true },
  )

  return data
}

export const updateEmployeeAssigneesMonthlyTimesheets = async ({ commit }, { employeeId, payload, year, month }) => {
  try {
    await axiosIns.patch(`company/staff/employee/${employeeId}/monthly-timesheets/`, payload, {
      params: { year, month },
    })
    commit('app/SET_SUCCESS_SNACK', i18n.t('done'), {
      root: true,
    })
  } catch (e) {
    commit('app/SET_ERROR_SNACK', i18n.t('somethingWentWrong'), {
      root: true,
    })
    console.error(e)
  }
}

export const generateEmployeeDailyTimesheetsReport = async (
  { commit },
  { employeeId, dateStart, dateEnd, fields, columnNames },
) => {
  try {
    const response = await axiosIns.get(`company/staff/employee/${employeeId}/daily-timesheets/generate-report/`, {
      params: { fields, date_start: dateStart, date_end: dateEnd, column_names: columnNames },
    })

    return {
      success: true,
      data: response.data,
      fileName: response.headers['content-disposition'].split('filename=')[1].split(';')[0],
    }
  } catch (e) {
    commit('app/SET_ERROR_SNACK', i18n.t('somethingWentWrong'), {
      root: true,
    })
    console.error(e)

    return { success: false }
  }
}

export const generateProjectDailyTimesheetsReport = async (
  { commit },
  { projectId, dateStart, dateEnd, fields, columnNames },
) => {
  try {
    const response = await axiosIns.get(`company/staff/project/${projectId}/daily-timesheets/generate-report/`, {
      params: { fields, date_start: dateStart, date_end: dateEnd, column_names: columnNames },
    })

    return {
      success: true,
      data: response.data,
      fileName: response.headers['content-disposition'].split('filename=')[1].split(';')[0],
    }
  } catch (e) {
    commit('app/SET_ERROR_SNACK', i18n.t('somethingWentWrong'), {
      root: true,
    })
    console.error(e)

    return { success: false }
  }
}

export const generatePositionDailyTimesheetsReport = async (
  { commit },
  { positionId, dateStart, dateEnd, fields, columnNames },
) => {
  try {
    const response = await axiosIns.get(`company/staff/position/${positionId}/daily-timesheets/generate-report/`, {
      params: { fields, date_start: dateStart, date_end: dateEnd, column_names: columnNames },
    })

    return {
      success: true,
      data: response.data,
      fileName: response.headers['content-disposition'].split('filename=')[1].split(';')[0],
    }
  } catch (e) {
    commit('app/SET_ERROR_SNACK', i18n.t('somethingWentWrong'), {
      root: true,
    })
    console.error(e)

    return { success: false }
  }
}

export const generateEmployeeMonthlyTimesheetsReport = async (
  { commit },
  { employeeId, dateStart, dateEnd, fields, columnNames },
) => {
  try {
    const response = await axiosIns.get(`company/staff/employee/${employeeId}/monthly-timesheets/generate-report/`, {
      params: { fields, date_start: dateStart, date_end: dateEnd, column_names: columnNames },
    })

    return {
      success: true,
      data: response.data,
      fileName: response.headers['content-disposition'].split('filename=')[1].split(';')[0],
    }
  } catch (e) {
    commit('app/SET_ERROR_SNACK', i18n.t('somethingWentWrong'), {
      root: true,
    })
    console.error(e)

    return { success: false }
  }
}

export const generateProjectMonthlyTimesheetsReport = async (
  { commit },
  { projectId, dateStart, dateEnd, fields, columnNames },
) => {
  try {
    const response = await axiosIns.get(`company/staff/project/${projectId}/monthly-timesheets/generate-report/`, {
      params: { fields, date_start: dateStart, date_end: dateEnd, column_names: columnNames },
    })

    return {
      success: true,
      data: response.data,
      fileName: response.headers['content-disposition'].split('filename=')[1].split(';')[0],
    }
  } catch (e) {
    commit('app/SET_ERROR_SNACK', i18n.t('somethingWentWrong'), {
      root: true,
    })
    console.error(e)

    return { success: false }
  }
}

export const generatePositionMonthlyTimesheetsReport = async (
  { commit },
  { positionId, dateStart, dateEnd, fields, columnNames },
) => {
  try {
    const response = await axiosIns.get(`company/staff/position/${positionId}/monthly-timesheets/generate-report/`, {
      params: { fields, date_start: dateStart, date_end: dateEnd, column_names: columnNames },
    })

    return {
      success: true,
      data: response.data,
      fileName: response.headers['content-disposition'].split('filename=')[1].split(';')[0],
    }
  } catch (e) {
    commit('app/SET_ERROR_SNACK', i18n.t('somethingWentWrong'), {
      root: true,
    })
    console.error(e)

    return { success: false }
  }
}

export const getResourceReminders = async (store, { resourceName, resourceId, hideDoneReminders }) => {
  const parentResourceName = resourceName === 'candidateOfferDetail' ? 'job' : 'staff'
  const { data } = await store.dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/${parentResourceName}/${_.kebabCase(resourceName)}/${resourceId}/reminders/`,
      params: { hide_done_reminders: hideDoneReminders },
    },
    { root: true },
  )

  return data
}

export const updateReminder = async (store, { reminderData }) => {
  const { id: reminderId, ...payload } = reminderData
  const { data, success } = await store.dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.PATCH,
      url: `company/staff/reminder/${reminderId}/`,
      payload,
    },
    { root: true },
  )

  return { data, success }
}

export const deleteReminder = async (store, { reminderId }) => {
  const { success } = await store.dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.DELETE,
      url: `company/staff/reminder/${reminderId}/`,
    },
    { root: true },
  )

  return { success }
}

export const createReminder = async (store, { resourceName, resourceId, reminderData }) => {
  const parentResourceName = resourceName === 'candidateOfferDetail' ? 'job' : 'staff'
  const { success } = await store.dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.POST,
      url: `company/${parentResourceName}/${_.kebabCase(resourceName)}/${resourceId}/reminders/`,
      payload: reminderData,
    },
    { root: true },
  )

  return { success }
}

export const getFilesChoices = async (store, { resourceName, resourceId }) => {
  const parentResourceName = resourceName === 'candidateOfferDetail' ? 'job' : 'staff'
  const { data } = await store.dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/${parentResourceName}/${_.kebabCase(resourceName)}/${resourceId}/files/choices/`,
    },
    { root: true },
  )

  return data
}

export const getAllReminders = async (
  store,
  { resources, owner, hideDoneReminders, search, page, pageSize, ordering },
) => {
  const { data, success } = await store.dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/reminders/all/`,
      params: {
        resources,
        owner,
        hide_done_reminders: hideDoneReminders,
        search,
        page,
        page_size: pageSize,
        ordering,
      },
    },
    { root: true },
  )

  return { data, success }
}

export const getAllRemindersFilterChoices = async (
  store,
  { resources, owner, hideDoneReminders, search, page, pageSize, ordering },
) => {
  const { data } = await store.dispatch(
    'core/axiosQueryBuilder',
    {
      type: QueryTypes.GET,
      url: `company/staff/reminders/filter-choices/`,
      params: {
        resources,
        owner,
        hide_done_reminders: hideDoneReminders,
        search,
        page,
        page_size: pageSize,
        ordering,
      },
    },
    { root: true },
  )

  return data
}

const performApiRequest = async (dispatch, type, urlSuffix, payload = null) => {
  const { success, data } = await dispatch(
    'core/axiosQueryBuilder',
    {
      type,
      url: `company/staff/${urlSuffix}/`,
      payload,
    },
    { root: true },
  )

  return { data, success }
}

export const deleteEmployeeInternalNote = async ({ dispatch }, internalNoteId) =>
  performApiRequest(dispatch, QueryTypes.DELETE, `employee/internal-note/${internalNoteId}`)

export const getHouseInternalNote = async ({ dispatch }, houseId) =>
  performApiRequest(dispatch, QueryTypes.GET, `house/${houseId}/internal-notes`)

export const createHouseInternalNote = async ({ dispatch }, payload) =>
  performApiRequest(dispatch, QueryTypes.POST, `house/${payload.house}/internal-notes`, payload)

export const deleteHouseInternalNote = async ({ dispatch }, internalNoteId) =>
  performApiRequest(dispatch, QueryTypes.DELETE, `house/internal-note/${internalNoteId}`)

export const getProjectInternalNote = async ({ dispatch }, projectId) =>
  performApiRequest(dispatch, QueryTypes.GET, `project/${projectId}/internal-notes`)

export const createProjectInternalNote = async ({ dispatch }, payload) =>
  performApiRequest(dispatch, QueryTypes.POST, `project/${payload.project}/internal-notes`, payload)

export const deleteProjectInternalNote = async ({ dispatch }, internalNoteId) =>
  performApiRequest(dispatch, QueryTypes.DELETE, `project/internal-note/${internalNoteId}`)

export const getClientInternalNote = async ({ dispatch }, clientId) =>
  performApiRequest(dispatch, QueryTypes.GET, `client/${clientId}/internal-notes`)

export const createClientInternalNote = async ({ dispatch }, payload) =>
  performApiRequest(dispatch, QueryTypes.POST, `client/${payload.client}/internal-notes`, payload)

export const deleteClientInternalNote = async ({ dispatch }, internalNoteId) =>
  performApiRequest(dispatch, QueryTypes.DELETE, `client/internal-note/${internalNoteId}`)

export default {
  getEmployees,
  getEmployeeChoices,
  getEmployeesOverview,
  getEmployeeDetails,
  deleteEmployee,
  getLanguagesOptions,
  getEmployeeFileTypes,
  updateEmployee,
  uploadEmployeeFiles,
  createEmployee,
  getHouses,
  getHousesOverview,
  getHouseDetails,
  getHousesFileTypes,
  uploadHouseFiles,
  getAllEmployeesChoices,
  updateHouseDetails,
  deleteHouse,
  createHouse,
  getHouseStatusChoices,
  getHouseStatuses,
  updateHouseStatuses,
  getAvailableOccupantsByRoomChoices,
  getHousesFilterChoices,
  getEmployeeStatusChoices,
  getEmployeeStatuses,
  updateEmployeeStatuses,
  getProjects,
  getClients,
  getClientsOverview,
  getClientsFileTypes,
  uploadClientFiles,
  getProjectDetails,
  updateProjectDetails,
  deleteProject,
  getProjectStatuses,
  updateProjectStatuses,
  getProjectsFilterChoices,
  getProjectsOverview,
  getProjectsFileTypes,
  uploadProjectFiles,
  getClientsFilterChoices,
  getClientStatuses,
  updateClientStatuses,
  getProjectStatusChoices,
  getClientDetails,
  updateClientDetails,
  deleteClient,
  getClientChoices,
  createProject,
  getClientStatusChoices,
  createClient,
  getAvailableAssigneesChoices,
  exportEmployees,
  exportHouses,
  exportProjects,
  exportClients,
  getHouseTags,
  updateHouseTags,
  getProjectTags,
  updateProjectTags,
  getClientTags,
  updateClientTags,
  getHouseCustomFields,
  updateHouseCustomFields,
  getClientCustomFields,
  updateClientCustomFields,
  getProjectCustomFields,
  updateProjectCustomFields,
  getProjectHourlyRatesCategories,
  updateProjectHourlyRatesCategories,
  deleteEmployeeFile,
  updateEmployeeFile,
  retrieveEmployeeFile,
  deleteHouseFile,
  updateHouseFile,
  deleteProjectFile,
  updateProjectFile,
  deleteClientFile,
  updateClientFile,
  getPositionDetails,
  updatePositionDetails,
  deletePosition,
  createPosition,
  getProjectsChoices,
  updateAssigneeDetails,
  deleteAssignee,
  createAssignees,

  getRoomDetails,
  updateRoomDetails,
  deleteRoom,
  createRoom,
  getHouseChoices,
  updateOccupantDetails,
  deleteOccupant,
  createOccupants,
  getRoomChoices,
  getRoomOccupantsChoices,
  createCustomAddress,
  updateCustomAddress,
  deleteCustomAddress,

  getHourlyRatesCategoriesChoices,
  getAssigneesDailyTimesheet,
  updateAssigneesDailyTimesheet,
  getAssigneesMonthlyTimesheet,
  updateAssigneesMonthlyTimesheet,
  getProjectAssigneesDailyTimesheets,
  updateProjectAssigneesDailyTimesheets,
  getProjectAssigneesMonthlyTimesheets,
  updateProjectAssigneesMonthlyTimesheets,
  getEmployeeAssigneesDailyTimesheets,
  updateEmployeeAssigneesDailyTimesheets,
  getEmployeeAssigneesMonthlyTimesheets,
  updateEmployeeAssigneesMonthlyTimesheets,
  generateEmployeeDailyTimesheetsReport,
  generateProjectDailyTimesheetsReport,
  generatePositionDailyTimesheetsReport,
  generateEmployeeMonthlyTimesheetsReport,
  generateProjectMonthlyTimesheetsReport,
  generatePositionMonthlyTimesheetsReport,

  // Reminders
  getResourceReminders,
  updateReminder,
  deleteReminder,
  createReminder,
  getFilesChoices,
  getAllReminders,
  getAllRemindersFilterChoices,

  deleteEmployeeInternalNote,
  getHouseInternalNote,
  createHouseInternalNote,
  deleteHouseInternalNote,
  getProjectInternalNote,
  createProjectInternalNote,
  deleteProjectInternalNote,
  getClientInternalNote,
  createClientInternalNote,
  deleteClientInternalNote,
}
