import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'
import core from './core'
import recruiter from './recruiter'
import company from './organization/company'
import jobs from './organization/jobs'
import staff from './organization/staff'
import talentPool from './organization/talentPool'
import dashboard from './organization/dashboard'
import templates from './organization/templates'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    appConfig: appConfigStoreModule,
    app,
    core,
    recruiter,
    company,
    jobs,
    staff,
    talentPool,
    dashboard,
    templates,
  },
})
