<template>
  <component :is="resolveLayoutVariant" :class="`skin-variant--${appSkinVariant}`">
    <transition-group :name="appRouteTransition" mode="out-in" appear>
      <v-snackbar
        key="notificationSnackbar"
        v-model="notification.visible"
        :color="notification.color"
        :timeout="notification.timeout"
        multi-line
        text
      >
        <span class="APP__span--snackbar">{{ notification.text }}</span>
        <template v-slot:action="{ attrs }">
          <v-icon v-bind="attrs" dark text @click="notification.visible = false"> mdi-close </v-icon>
        </template>
      </v-snackbar>
      <v-snackbar
        v-if="$route.meta.resource !== 'Public'"
        key="newVersionNotificationSnackbar"
        v-model="newVersionNotification.visible"
        timeout="-1"
        dark
        top
        centered
        multi-line
        text
      >
        <div>Hurra &#x1F389; {{ $t('newVersion') }}</div>
        <template v-slot:action="{ attrs }">
          <v-btn v-t="'refresh'" color="#F2A067" text v-bind="attrs" @click="refresh" />
        </template>
      </v-snackbar>
    </transition-group>
    <transition :name="appRouteTransition" mode="out-in" appear>
      <router-view></router-view>
    </transition>
  </component>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed, onMounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'
import { useLayout } from '@core/layouts/composable/useLayout'
import { useState, useMutations } from 'vuex-composition-helpers'

// Dynamic vh
import useDynamicVh from '@core/utils/useDynamicVh'
import { loadLanguageAsync } from '@/plugins/i18n'

// Layouts
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'

export default {
  components: {
    LayoutContentVerticalNav,
    LayoutContentHorizontalNav,
    LayoutBlank,
  },
  setup() {
    // const vm = getCurrentInstance().proxy

    const { route } = useRouter()
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()

    const { notification, newVersionNotification } = useState('app', ['notification', 'newVersionNotification'])

    const { REMOVE_NEW_VERSION_NOTIFICATION } = useMutations('app', ['REMOVE_NEW_VERSION_NOTIFICATION'])
    const refresh = () => {
      REMOVE_NEW_VERSION_NOTIFICATION()
      window.location.reload(true)
    }

    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav`

      return null
    })

    useDynamicVh()

    onMounted(() => {
      if (localStorage.getItem('locale')) loadLanguageAsync(localStorage.getItem('locale'))
    })

    // const isIdle = computed(() => {
    //   console.log('vm.$store.state.idleVue.isIdle', vm.$store.state.idleVue.isIdle)
    //
    //   return vm.$store.state.idleVue.isIdle
    // })

    return {
      notification,
      newVersionNotification,
      refresh,
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,

      // isIdle,
    }
  },
}
</script>
